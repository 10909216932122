import React from 'react';
import { color } from 'theme';

export const SnowIcon = () => (
  <svg
    className="weather-icon"
    enableBackground="new 0 0 64 64"
    height="64px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 64 64"
    width="64px"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M55,40c4.565,0,8-3.435,8-8c0-4.565-3.435-9-8-9
	c0-11.414-9.586-20-21-20C23.898,3,14.8,9.423,13,19c0,0-1.165,0-2,0C5.292,19,1,24.292,1,30s4.292,10,10,10H55z"
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="22"
      x2="22"
      y1="43"
      y2="48"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="22"
      x2="22"
      y1="52"
      y2="57"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="27"
      x2="24"
      y1="45"
      y2="48"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="20"
      x2="17"
      y1="52"
      y2="55"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="27"
      x2="24"
      y1="55"
      y2="52"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="20"
      x2="17"
      y1="48"
      y2="45"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="29"
      x2="24"
      y1="50"
      y2="50"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="20"
      x2="15"
      y1="50"
      y2="50"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="42"
      x2="42"
      y1="48"
      y2="53"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="42"
      x2="42"
      y1="57"
      y2="62"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="47"
      x2="44"
      y1="50"
      y2="53"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="40"
      x2="37"
      y1="57"
      y2="60"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="47"
      x2="44"
      y1="60"
      y2="57"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="40"
      x2="37"
      y1="53"
      y2="50"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="49"
      x2="44"
      y1="55"
      y2="55"
    />
    <line
      fill={color.red}
      stroke={color.red}
      strokeMiterlimit="10"
      strokeWidth="2"
      x1="40"
      x2="35"
      y1="55"
      y2="55"
    />
  </svg>
);
