import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useGetCoordinates } from 'hooks';
import { MapWithDirection } from './MapWithDirection';

export const GameInfoModal = ({
  show = false,
  onClose,
  stadium,
  stadiumLocation,
  city,
  region,
}) => {
  const isStadiumCoordinates = !!stadiumLocation.lng && !!stadiumLocation.lat;

  const {
    status: coordinatesStatus,
    data: coordinatesData,
    isLoading: coordinatesIsLoading,
  } = useGetCoordinates({ stadium, isStadiumCoordinates, region, show });

  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  useEffect(() => {
    if (show && !coordinatesIsLoading && !coordinatesData?.results?.length) {
      toast.error(
        'Location info for selected game is currently unavailable. Please try again later.',
        { toastId: 'error-1' }
      );
    }
  }, [coordinatesData?.results?.length, show, coordinatesIsLoading]);

  if (!show) {
    return null;
  }

  return (
    <MapWithDirection
      city={city}
      coordinatesIsLoading={coordinatesIsLoading}
      coordinatesStatus={coordinatesStatus}
      isLoading={coordinatesIsLoading}
      location={{
        lat:
          stadiumLocation.lat ||
          coordinatesData?.results[0]?.geometry?.location?.lat,
        lng:
          stadiumLocation.lng ||
          coordinatesData?.results[0]?.geometry?.location?.lng,
      }}
      onClose={onClose}
      show={show}
      stadium={stadium}
    />
  );
};
