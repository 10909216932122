import React from 'react';
import { LargeText } from 'theme/titles';
import { ErrorMessageWrapper } from './style';

const DEFAULT_TITLE = `Ooops... It seems, there is an error on our side. We'are working on it.
Please, try again later.`;

export const ErrorMessage = ({ title }) => (
  <ErrorMessageWrapper>
    <LargeText>{title || DEFAULT_TITLE}</LargeText>
  </ErrorMessageWrapper>
);
