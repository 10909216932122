import styled from 'styled-components';
import { color, device, fontSize } from 'theme';

export const StyledButton = styled.button`
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  color: ${color.red};
  font-size: ${fontSize.small};
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px ${color.red};
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    background: ${color.red};
    color: ${color.backgroundDark};

    svg > path {
      fill: ${color.backgroundDark};
    }
  }

  @media ${device.tablet} {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: ${fontSize.medium};
  }

  @media ${device.laptop} {
    padding: 10px 15px;
    border-radius: 10px;
    font-size: ${fontSize.large};
  }
`;

export const CloseRoundButton = styled.button`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid ${color.red};
  color: ${color.red};
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid ${color.backgroundDark};
    background-color: ${color.red};

    &:before {
      color: ${color.backgroundDark};
    }
  }

  &::before {
    content: 'X';
  }
`;
