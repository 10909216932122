import { all, fork } from 'redux-saga/effects';
import { configWatcher } from './config/actions';
import { gamesWatcher } from './games/actions';
import { leaguesWatcher } from './leagues/actions';
import { teamsWatcher } from './teams/actions';
import { userLoginWatcher } from './user/actions';

export default function* rootSaga() {
  yield all([
    fork(leaguesWatcher),
    fork(teamsWatcher),
    fork(gamesWatcher),
    fork(configWatcher),
    fork(userLoginWatcher),
  ]);
}
