import types from '../types';

const initialState = {
  email: '',
  facebookId: '',
  firebaseToken: null,
  googleId: '',
  token: '',
  user_id: '',
};

const user = (
  state = { data: initialState, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case types.USER_LOGIN_START:
      return { ...state, loading: true };
    case types.USER_LOGIN_SUCCESS:
      return { data: action.payload, error: null, loading: false };
    case types.USER_LOGIN_ERROR:
      return { data: initialState, error: action.payload, loading: false };
    case types.USER_LOGOUT:
      return { data: initialState, error: null, loading: false };
    case types.USER_LOGIN_SET:
      return { data: action.payload, error: null, loading: false };
    case types.USER_LOGIN_RESET:
      return { data: initialState, error: null, loading: false };

    default:
      return state;
  }
};

export default user;
