export const DEFAULT_TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const SIGN_UP = 'Sign Up';
export const LOGOUT = 'Sign Out';
export const LOGIN = 'Sign In';
export const FORGOT_PASSWORD = 'Forgot Password?';
export const PASSWORD = 'Password';
export const YOUR_EMAIL = 'Your email';

export const UPCOMING_GAMES_LABEL = 'Upcoming Events';
export const TODAYS_GAMES_LABEL = "Today's Events";

export const UPCOMING_GAMES_ITEM = {
  label: UPCOMING_GAMES_LABEL,
  value: 'ug-1',
};

export const TODAYS_GAMES_ITEM = {
  label: TODAYS_GAMES_LABEL,
  value: 'td-1',
};
