import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  InfoIcon,
  MapIcon,
  TicketIcon,
  TransportIcon,
  WeatherIcon,
} from 'assets/icons';
import { Tooltip } from 'components';
import { GameInfoModal } from './components/GameInfoModal';
import { WeatherInfoModal } from './components/WeatherInfoModal';
import {
  GameInfoContainer,
  InfoIconContainer,
  ExpandedMenuContainer,
  IconContainer,
  StyledLink,
} from './style';

const SEARCH_TICKET_URL = process.env.REACT_APP_SEARCH_TICKET_URL;
const SEARCH_FLIGHT_URL = process.env.REACT_APP_EXPEDIA_SEARCH_FLIGHT_URL;

export const GameInfoButton = ({
  team,
  opponent,
  date,
  city,
  stadium,
  stadiumLocation,
  zipCode,
  timeZone,
  region,
}) => {
  // Log google analytics events
  const analytics = getAnalytics();
  const ref = useRef(null);

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWeatherModal, setShowWeatherModal] = useState(false);

  const modalHandler = useCallback(() => {
    logEvent(analytics, 'select_content', {
      content_type: 'Show game info',
      content_id: `${team} - ${opponent}`,
    });

    setExpandedMenu((prevState) => !prevState);
  }, [analytics, opponent, team]);

  const clickMapHandler = useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, []);

  const clickWeatherHandler = useCallback(() => {
    setShowWeatherModal((prevState) => !prevState);
  }, []);

  const handleClickOutside = (e) => {
    if (
      expandedMenu &&
      ref.current &&
      !ref.current?.contains(e.target) &&
      !showModal &&
      !showWeatherModal
    ) {
      setExpandedMenu(false);
    }
  };

  const isWeatherDisabled = moment(date).isBefore(moment());

  useEffect(() => {
    if (expandedMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <GameInfoContainer>
      <InfoIconContainer onClick={modalHandler}>
        <InfoIcon />
      </InfoIconContainer>
      <ExpandedMenuContainer $isOpen={expandedMenu} ref={ref}>
        <IconContainer onClick={clickMapHandler}>
          <MapIcon />
        </IconContainer>
        <Tooltip
          isActive={isWeatherDisabled}
          text="Weather forecast is not available for past games"
        >
          <IconContainer
            $isDisabled={isWeatherDisabled}
            onClick={clickWeatherHandler}
          >
            <WeatherIcon />
          </IconContainer>
        </Tooltip>

        <StyledLink $ml={60} target="_blank" to={SEARCH_TICKET_URL}>
          <TicketIcon />
        </StyledLink>

        <StyledLink target="_blank" to={SEARCH_FLIGHT_URL}>
          <TransportIcon />
        </StyledLink>
      </ExpandedMenuContainer>
      {showModal
        ? ReactDOM.createPortal(
            <GameInfoModal
              city={city}
              onClose={clickMapHandler}
              opponent={opponent}
              region={region ? region.toLowerCase() : ''}
              show={showModal}
              stadium={stadium}
              stadiumLocation={stadiumLocation}
              team={team}
              timeZone={timeZone}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}
      {showWeatherModal
        ? ReactDOM.createPortal(
            <WeatherInfoModal
              date={date}
              onClose={clickWeatherHandler}
              show={showWeatherModal}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}
    </GameInfoContainer>
  );
};
