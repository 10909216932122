import React from 'react';

export const WeatherIcon = () => (
  <svg
    id="Icons"
    style={{ enableBackground: 'new 0 0 32 32' }}
    version="1.1"
    viewBox="0 0 32 32"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <line
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeWidth="1"
      x1="16"
      x2="16"
      y1="3"
      y2="5"
    />
    <path
      d="M16,18v8v0.5c0,1.38-1.12,2.5-2.5,2.5S11,27.88,11,26.5"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeWidth="1"
    />
    <path
      d="M27,11c0,2.21-1.79,4-4,4s-4-1.79-4-4s4-8,4-8S27,8.79,27,11z"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeWidth="1"
    />
    <path
      d="M21.08,6.03C19.52,5.37,17.8,5,16,5C8.82,5,3,10.82,3,18h26c0-2.45-0.68-4.75-1.86-6.71"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeWidth="1"
    />
  </svg>
);
