import React from 'react';
import moment from 'moment';

import {
  TemperatureIcon,
  RainIcon,
  SnowIcon,
  HumidityIcon,
  SunIcon,
  WindIcon,
} from 'assets/icons';

import {
  WeatherContainer,
  WeatherIcon,
  WeatherInfoSection,
  WeatherInfoIconSection,
  IconImage,
  ErrorContainer,
} from '../style';

export const WeatherGameInfo = ({ data, date }) => {
  const localTimezone = data?.location.tz_id;
  const forecastDays = data?.forecast.forecastday;
  const foundDayWeather = forecastDays.find(
    (forecastDay) =>
      forecastDay.date ===
      moment.utc(date).tz(localTimezone).format('YYYY-MM-DD')
  );

  const isImperialMetric = data?.location.tz_id.includes('America');

  if (!foundDayWeather?.day) {
    return (
      <ErrorContainer>
        Weather forecast for game day is currently unavailable.
      </ErrorContainer>
    );
  }

  const gameDayWeather = foundDayWeather?.day;

  return (
    <WeatherContainer>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <TemperatureIcon />
          </WeatherIcon>
          High / Low
        </WeatherInfoIconSection>{' '}
        {isImperialMetric
          ? `${gameDayWeather.maxtemp_f}F`
          : `${gameDayWeather.maxtemp_с}C`}{' '}
        /{' '}
        {isImperialMetric
          ? `${gameDayWeather.mintemp_f}F`
          : `${gameDayWeather.mintemp_с}C`}
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <RainIcon />
          </WeatherIcon>
          Chance of rain:
        </WeatherInfoIconSection>{' '}
        {gameDayWeather.daily_chance_of_rain}%
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <SnowIcon />
          </WeatherIcon>
          Chance of snow:
        </WeatherInfoIconSection>{' '}
        {gameDayWeather.daily_chance_of_snow}%
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <HumidityIcon />
          </WeatherIcon>
          Humidity:
        </WeatherInfoIconSection>{' '}
        {gameDayWeather.avghumidity}%
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <WindIcon />
          </WeatherIcon>
          Wind:
        </WeatherInfoIconSection>{' '}
        {isImperialMetric
          ? `${gameDayWeather.maxwind_mph}m/h`
          : `${gameDayWeather.maxwind_kph}km/h`}
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <SunIcon />
          </WeatherIcon>
          UV Index:
        </WeatherInfoIconSection>{' '}
        {gameDayWeather.uv}
      </WeatherInfoSection>
      <WeatherInfoSection>
        <WeatherInfoIconSection>
          <WeatherIcon>
            <IconImage src={gameDayWeather.condition.icon} />
          </WeatherIcon>
          Condition:
        </WeatherInfoIconSection>{' '}
        {gameDayWeather.condition.text}
      </WeatherInfoSection>
    </WeatherContainer>
  );
};
