import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { color, fontSize } from 'theme';

export const CustomLink = styled(NavLink)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: ${color.red};
  font-size: ${fontSize.large};
  letter-spacing: 0.05em;
`;
