import React, { useEffect, useState } from 'react';
import backgroundImg from 'assets/img/basic-background.jpg';
import { AuthWrapper } from 'components/AuthContainer/AuthWrapper';
import Modal from 'components/Modal';
import SignupForm from 'components/AuthContainer/SignupForm';

import { BodyContainer } from 'components/App/style.js';
import { BackgroundImg } from 'components/Header/style.js';

const Signup = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <BodyContainer>
      <BackgroundImg $imgUrl={`${backgroundImg}`} />
      <Modal show={show} showCloseBtn={false}>
        <AuthWrapper>
          <SignupForm />
        </AuthWrapper>
      </Modal>
    </BodyContainer>
  );
};

export default Signup;
