import React, { memo } from 'react';

import { CountdowItem, CountdowItemNumber, CountdowItemDesc } from '../style';

const Days = ({ days }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {days >= 0 ? (
      <CountdowItem>
        <CountdowItemNumber>{days < 10 ? '0' + days : days}</CountdowItemNumber>
        <CountdowItemDesc>day</CountdowItemDesc>
      </CountdowItem>
    ) : null}
  </>
);

function arePropsEqual(prevProps, nextProps) {
  return prevProps.days === nextProps.days;
}

export default memo(Days, arePropsEqual);
