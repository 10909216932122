import { DEFAULT_TOAST_OPTIONS, SIGN_UP } from 'constants/names.js';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { validateEmail } from 'helpers';
import api from 'api/api.js';
import { getAnalytics, logEvent } from 'firebase/analytics';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';
import Link from 'components/Link';
import { BackIcon } from 'assets/icons';

import { LinksContainer, ArrowWrapper } from '../style.js';

const SignupForm = () => {
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState(false);

  const { mutate, isLoading } = useMutation(
    'registerUser',
    () =>
      api.user.registerUser({
        email,
        password,
      }),
    {
      onSuccess: () => {
        toast.success('You registration was successful. You can sign in now.', {
          ...DEFAULT_TOAST_OPTIONS,
          autoClose: 4000,
        });

        setTimeout(() => {
          navigate('/');
        }, 4500);
      },
      onError: (error) => {
        toast.error(`${error.response.data.error}`, DEFAULT_TOAST_OPTIONS);
        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `An error occurs during registration user with email ${email}, server response: ${error.response.data.error}. Site url: ${window.location.href}`,
        });
      },
    }
  );

  const emailErrorHandler = useCallback(() => {
    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  }, [email]);

  const emailInputHandler = useCallback((inputValue) => {
    setEmail(inputValue);
    setEmailError(false);
  }, []);

  const signupHandler = useCallback(() => {
    if (!emailError && email && password) {
      mutate({ email, password });
    }
  }, [email, password, emailError, mutate]);

  const isPasswordMatch = password === confirmPassword;

  const isButtonDisabled =
    !email || !password || !confirmPassword || !isPasswordMatch || emailError;

  const signUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        signupHandler();
      }
    },
    [signupHandler]
  );

  useEffect(() => {
    window.addEventListener('keydown', signUp);

    return () => window.removeEventListener('keydown', signUp);
  }, [signUp]);

  return (
    <>
      <CustomInput
        isError={emailError}
        onBlur={emailErrorHandler}
        onChange={emailInputHandler}
        placeholder="Email"
        type="email"
        value={email}
      />
      <CustomInput
        isPassword
        onChange={setPassword}
        placeholder="Password"
        type="password"
        value={password}
      />
      <CustomInput
        isError={password && confirmPassword ? !isPasswordMatch : null}
        isPassword
        onChange={setConfirmPassword}
        placeholder="Confirm password"
        type="password"
        value={confirmPassword}
      />
      <LinksContainer>
        <Link route="/" title="Back to main page">
          <ArrowWrapper>
            <BackIcon />
          </ArrowWrapper>
        </Link>
      </LinksContainer>
      <Button
        disabled={isButtonDisabled || isLoading}
        onClick={signupHandler}
        title={SIGN_UP}
      />
    </>
  );
};

export default SignupForm;
