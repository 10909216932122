import { MOBILE_APP_SITES } from 'constants';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { GooglePlayStoreIcon, AppleStoreIcon } from 'assets/icons';

const StyledStoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: center;

  animation: appearance 1.5s ease-in;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledStoreLink = styled.a`
  display: block;
  width: 135px;
  height: 44px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const hostName = window.location.hostname;
const shouldShowStoreIcons = MOBILE_APP_SITES.includes(hostName);

export const AppStoreLinks = () => {
  const { error: isConfigError, data: configData } = useSelector(
    (state) => state.configuration
  );

  if (isConfigError) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {shouldShowStoreIcons ? (
        <StyledStoreContainer>
          {configData?.app_ios_store_url ? (
            <StyledStoreLink
              href={configData?.app_ios_store_url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <AppleStoreIcon />
            </StyledStoreLink>
          ) : null}
          {configData?.app_android_store_url ? (
            <StyledStoreLink
              href={configData?.app_android_store_url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <GooglePlayStoreIcon />
            </StyledStoreLink>
          ) : null}
        </StyledStoreContainer>
      ) : null}
    </>
  );
};
