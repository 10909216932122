export const generatePageTitle = (data = {}) => {
  try {
    data.title = data.title || 'Downkount';
    data.metaDescription = data.metaDescription || 'The Countdown Continues...';

    document.title = data.title.toString();
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', data.metaDescription.toString());
  } catch (error) {
    console.log(error);
  }
};
