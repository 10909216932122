import React from 'react';

import { CountdowItem, CountdowItemNumber, CountdowItemDesc } from '../style';

const Seconds = ({ seconds }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {seconds >= 0 ? (
      <CountdowItem>
        <CountdowItemNumber>
          {seconds < 10 ? '0' + seconds : seconds}
        </CountdowItemNumber>
        <CountdowItemDesc>sec</CountdowItemDesc>
      </CountdowItem>
    ) : null}
  </>
);

export default Seconds;
