import { useQuery } from 'react-query';
import { getGeoCoordinates } from 'api/api';

export const useGetCoordinates = ({
  stadium,
  isStadiumCoordinates,
  region,
  show,
}) =>
  useQuery({
    queryKey: ['getCoordinates', show, stadium, region],
    queryFn: () => getGeoCoordinates({ stadium, region }),
    enabled: (!isStadiumCoordinates || !!stadium) && show,
  });
