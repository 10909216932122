import { LOCAL_STORAGE_KEY } from 'constants';
import { useEffect, useState } from 'react';

export default function useLocalStorage(key, initValue) {
  const prefixedKey = LOCAL_STORAGE_KEY + key;

  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(prefixedKey);

    if (jsonValue) return JSON.parse(jsonValue);
    if (typeof initValue === 'function') {
      return initValue();
    } else {
      return initValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(prefixedKey, JSON.stringify(value));
  }, [prefixedKey, value]);

  return [value, setValue];
}
