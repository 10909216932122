import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { api } from 'api/api';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  favoriteTeamsError,
  favoriteTeamsSuccess,
} from 'redux/favoriteTeams/actionCreators.js';

import { Star } from './style.js';

export const AddToFavorite = ({ userId }) => {
  const analytics = getAnalytics();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const favoriteTeams = useSelector((state) => state.favoriteTeams.data);

  //** GET ALL FAVORITE TEAMS FOR LOGIN USER **//
  const getAllFavoriteTeams = useMutation(
    (id) => api.favorites.getFavoritesTeams(id),
    {
      onError: () => {
        dispatch(favoriteTeamsError());
        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `Favorite teams fetching error for user - ${userId}. Team ${params?.team}, teamId ${params?.teamId} and site url: ${window.location.href}`,
        });
      },
      onSuccess: (data) => {
        dispatch(favoriteTeamsSuccess(data.data));
      },
    }
  );

  const [favTeam, setFavTeam] = useState(false);

  const addToFavoriteTeams = useMutation(
    (data) => api.favorites.addToFavoriteTeams(data),
    {
      onError: () => {
        toast.error(
          `An error occurred while adding ${params?.team} to your favorites list. Please try again later.`
        );

        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `Adding to favorites list error. User with userId ${userId}. Team ${params?.team}, teamId ${params?.teamId} and site url: ${window.location.href}`,
        });
      },
      onSuccess: () => {
        setFavTeam(true);
        toast.success(`${params?.team} added to your favorites list.`);
        getAllFavoriteTeams.mutate(userId);
      },
    }
  );

  const deleteFavoriteTeam = useMutation(
    (data) => api.favorites.deleteFavoriteTeam(data),
    {
      onError: () => {
        toast.error(
          `An error occurred while removing ${params?.team} from your favorites list. Please try again later.`
        );

        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `Deleting from favorites list error. User with userId ${userId}. Team ${params?.team}, teamId ${params?.teamId} and site url: ${window.location.href}`,
        });
      },
      onSuccess: () => {
        setFavTeam(false);
        toast.success(`${params?.team} removed from your favorites list.`);
        getAllFavoriteTeams.mutate(userId);
      },
    }
  );

  const isDisabled =
    !!params?.team &&
    !!params?.teamId &&
    !!params?.league &&
    !!params?.leagueId;

  const handleFavoriteClick = useCallback(() => {
    const { teamId, team } = params;

    if (userId && favTeam) {
      const data = {
        teamId,
        userId,
      };
      deleteFavoriteTeam.mutate(data);

      logEvent(analytics, 'select_content', {
        content_type: 'Delete team from favorite',
        content_id: team,
      });

      return;
    }

    if (userId && teamId && team) {
      addToFavoriteTeams.mutate({
        userId,
        teamId: teamId,
        teamLabel: team,
      });

      logEvent(analytics, 'select_content', {
        content_type: 'Add team to favorite',
        content_id: team,
      });
    }
  }, [
    addToFavoriteTeams,
    analytics,
    deleteFavoriteTeam,
    favTeam,
    params,
    userId,
  ]);

  useEffect(() => {
    const isTeamFavorite = favoriteTeams?.filter(
      (team) =>
        team.favourite_teamLabel === params?.team &&
        team.favourite_team_id === Number(params?.teamId)
    );

    if (isTeamFavorite?.length) {
      setFavTeam(true);
    } else {
      setFavTeam(false);
    }
  }, [params.team, params?.teamId, favoriteTeams]);

  return (
    <Star
      $isDisabled={
        !isDisabled ||
        addToFavoriteTeams.isLoading ||
        deleteFavoriteTeam.isLoading
      }
      $isFavorite={favTeam}
      onClick={handleFavoriteClick}
    />
  );
};
