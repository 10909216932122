import React from 'react';
import GoogleMapReact from 'google-map-react';

import { GeoLocationIcon } from 'assets/icons';
import { PointOnMapWrap, MarkerWrapper, MapWrapper } from './style';

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const Marker = ({ lat, lng }) => (
  <PointOnMapWrap>
    <MarkerWrapper lat={lat} lng={lng}>
      <GeoLocationIcon />
    </MarkerWrapper>
  </PointOnMapWrap>
);

const GoogleMap = ({ location }) => (
  <MapWrapper>
    <GoogleMapReact
      bootstrapURLKeys={{ key: googleApiKey }}
      defaultCenter={{
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      }}
      defaultZoom={12}
    >
      <Marker lat={parseFloat(location.lat)} lng={parseFloat(location.lng)} />
    </GoogleMapReact>
  </MapWrapper>
);

export default GoogleMap;
