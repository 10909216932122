import React from 'react';

export const SunIcon = () => (
  <svg
    className="weather-icon"
    enableBackground="new 0 0 50 50"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M31.324,25.224c0-3.86-3.141-7-7-7s-7,3.14-7,7c0,3.859,3.141,7,7,7S31.324,29.083,31.324,25.224z
	 M24.324,30.224c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S27.081,30.224,24.324,30.224z"
      fill="#231F20"
    />
    <path
      d="M23.324,34.09v6.134c0,0.553,0.447,1,1,1s1-0.447,1-1V34.09c0-0.553-0.447-1-1-1S23.324,33.537,23.324,34.09
	z"
      fill="#231F20"
    />
    <path
      d="M23.324,10.224v6.134c0,0.552,0.447,1,1,1s1-0.448,1-1v-6.134c0-0.552-0.447-1-1-1
	S23.324,9.671,23.324,10.224z"
      fill="#231F20"
    />
    <path
      d="M34.224,36.537c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
	c0.391-0.391,0.391-1.023,0-1.414L31.3,30.786c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L34.224,36.537z"
      fill="#231F20"
    />
    <path
      d="M13.011,13.91c-0.391,0.391-0.391,1.023,0,1.414l4.338,4.337c0.195,0.195,0.451,0.293,0.707,0.293
	s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-4.338-4.337C14.034,13.52,13.401,13.52,13.011,13.91z"
      fill="#231F20"
    />
    <path
      d="M39.324,24.224H33.19c-0.553,0-1,0.448-1,1s0.447,1,1,1h6.134c0.553,0,1-0.448,1-1
	S39.877,24.224,39.324,24.224z"
      fill="#231F20"
    />
    <path
      d="M8.324,25.224c0,0.552,0.447,1,1,1h6.134c0.553,0,1-0.448,1-1s-0.447-1-1-1H9.324
	C8.771,24.224,8.324,24.671,8.324,25.224z"
      fill="#231F20"
    />
    <path
      d="M34.224,13.91l-4.338,4.338c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293
	s0.512-0.098,0.707-0.293l4.338-4.338c0.391-0.391,0.391-1.023,0-1.414S34.614,13.52,34.224,13.91z"
      fill="#231F20"
    />
    <path
      d="M13.718,36.83c0.256,0,0.512-0.098,0.707-0.293l4.338-4.337c0.391-0.391,0.391-1.023,0-1.414
	s-1.023-0.391-1.414,0l-4.338,4.337c-0.391,0.391-0.391,1.023,0,1.414C13.206,36.732,13.462,36.83,13.718,36.83z"
      fill="#231F20"
    />
  </svg>
);
