import React from 'react';
import styled from 'styled-components';

import { device } from 'theme';
import { LargeText } from 'theme/titles';

const ChoiceTitleContainer = styled.div`
  padding-top: 0rem;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    width: 60%;
  }
`;

export const ChoiceTitle = ({ title }) => (
  <ChoiceTitleContainer>
    <LargeText>{title}</LargeText>
  </ChoiceTitleContainer>
);
