import React from 'react';

export const GeoLocationIcon = () => (
  <svg
    fill="none"
    height="60"
    viewBox="0 0 60 60"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.072 5.00012C18.5494 4.96098 9.13774 14.1284 8.76157 25.6034L8.75 26.3156C8.94152 31.9402 10.8552 37.3017 14.2257 41.7191L15.0173 42.7521C18.2345 46.8428 22.0578 50.4333 26.3507 53.3928L27.3843 54.0886L27.5351 54.207C29.0065 55.2642 30.9931 55.2642 32.4645 54.207L32.6209 54.0784L32.5601 54.1232C35.978 51.9099 39.1292 49.3077 41.9512 46.3682C47.6715 40.313 51.1193 33.5281 51.2494 26.6375L51.2498 26.3816C51.2888 14.8298 42.1496 5.38918 30.7099 5.01173L30.072 5.00012Z"
      fill="#EB5757"
    />
    <path
      d="M29.9997 18.5034C25.5287 18.5034 21.9043 22.1391 21.9043 26.6238C21.9043 31.1086 25.5287 34.7443 29.9997 34.7443C34.4707 34.7443 38.0952 31.1086 38.0952 26.6238C38.0952 22.1391 34.4707 18.5034 29.9997 18.5034Z"
      fill="white"
    />
  </svg>
);
