import React from 'react';
import { NotificationWrapper, IconWrapper, Image } from './style';

export const NotificationComponent = ({ title, body, img }) => (
  <NotificationWrapper>
    {img ? (
      <IconWrapper>
        <Image alt="notification icon" src={img} />
      </IconWrapper>
    ) : null}
    <div>
      <p>{title}</p>
      {body ? <p>{body}</p> : null}
    </div>
  </NotificationWrapper>
);
