import { LOGOUT } from 'constants/names.js';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import { clearLocalStorage } from 'helpers';
import types from 'redux/types';

import Button from 'components/Button';

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

export const GoogleLogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = () => {
    dispatch({
      type: types.USER_LOGIN_RESET,
    });

    dispatch({
      type: types.SET_SELECTED_TEAM,
      payload: { teamName: '', teamId: '' },
    });

    dispatch({
      type: types.SET_SELECTED_LEAGUE,
      payload: { leagueName: '', leagueId: '' },
    });

    clearLocalStorage();

    setTimeout(() => {
      navigate('/');
      window.location.reload();
    }, 1000);
  };

  return (
    <GoogleLogout
      buttonText={LOGOUT}
      clientId={clientId}
      onLogoutSuccess={onSuccess}
      render={(renderProps) => (
        <Button onClick={renderProps.onClick} title={LOGOUT} />
      )}
    />
  );
};
