export const MOBILE_APP_SITES = [
  'localhost',
  'bucksti.me',
  'bullsti.me',
  'cavstime.com',
  'celtstime.com',
  'dubti.me',
  'griztime.com',
  'knickstime.com',
  'lakertime.com',
  'spurstime.com',
  'bigblueti.me',
  'bigbluetime.com',
];
