import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api/api';
import types from '../types';

//** API FUNCTION GET LEAGUES LIST */

function* getLeagues(payload) {
  if (!payload.payload) {
    try {
      const { status, data } = yield call(api.leagues.getLeagues);
      const { data: todaysEvents } = yield call(api.games.getTodaysEvents);
      if (todaysEvents) {
        yield put({
          type: types.GET_TODAYS_GAMES_SUCCESS,
          payload: todaysEvents,
        });
      }
      if (status < 200 || status >= 300)
        yield put({
          type: types.GET_LEAGUES_ERROR,
          payload:
            "It's seems that service is currently unavailable. Try again later",
        });
      if (!data.length) {
        yield put({
          type: types.GET_LEAGUES_ERROR,
          payload: "It's seems that we can't show leagues list",
        });
      }
      yield put({ type: types.GET_LEAGUES_SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: types.GET_LEAGUES_ERROR,
        payload:
          "It's seems that service is currently unavailable. Try again later",
      });
    }
  } else {
    yield put({ type: types.GET_LEAGUES_SUCCESS, payload: payload.payload });
  }
}

export function* leaguesWatcher() {
  yield takeLatest(types.GET_LEAGUES_START, getLeagues);
}
