import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

import { color } from 'theme';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const Loading = () => (
  <Wrapper>
    <LoaderContainer>
      <TailSpin color={color.red} height={80} timeout={2500} width={80} />
    </LoaderContainer>
  </Wrapper>
);
