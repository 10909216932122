import React, { memo } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { dateFormat } from 'helpers';
import { useGetUserTimeZone } from 'hooks';

import { DateContainer } from './style';

const EventDate = ({ eventTime }) => {
  const { timeZone: userTimeZone } = useGetUserTimeZone();

  return (
    <DateContainer>
      <Moment element="span" format="dddd, MMMM D,  YYYY">
        {moment.utc(dateFormat(eventTime)).tz(userTimeZone)}
      </Moment>
    </DateContainer>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.eventTime === nextProps.eventTime;
}

export default memo(EventDate, arePropsEqual);
