import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { dateFormat } from 'helpers';
import { useGetUserTimeZone } from 'hooks';

import EndedGame from './EndedGame';
import CountDownTimer from './CountDownTimer';

import { ResultContainer, TBDText } from './style';

export const GameResult = ({ date, result, endDate = null, eventState }) => {
  const gameDate = useMemo(() => moment.utc(dateFormat(date)), [date]);
  const todayDate = moment.utc();
  const { timeZone: userTimeZone } = useGetUserTimeZone();

  // END GAME TIME = EVENT_START + 4 hours
  const gameEndTime = endDate
    ? moment
        .utc(dateFormat(endDate))
        .tz(userTimeZone || 'America/New_York')
        .add(4, 'h')
    : moment
        .utc(dateFormat(date))
        .tz(userTimeZone || 'America/New_York')
        .add(4, 'h');

  const isGameInProgress = todayDate > gameDate && todayDate < gameEndTime;
  const isGameEnded = todayDate > gameEndTime;

  if (eventState === '2') return <TBDText>TBD</TBDText>;

  return (
    <ResultContainer>
      {isGameEnded || isGameInProgress ? (
        <EndedGame isGameInProgress={isGameInProgress} result={result} />
      ) : (
        !isGameInProgress && <CountDownTimer timeTillDate={gameDate} />
      )}
    </ResultContainer>
  );
};
