import {
  championshipLeagues,
  UPCOMING_GAMES_ITEM,
  ROUTES,
  TODAYS_GAMES_LABEL,
} from 'constants';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { allGamesResetAction } from 'redux/games/actionCreators.js';
import { useGetSortedLeaguesList } from 'hooks';
import { createSelectedValue } from 'helpers';
import { Selector } from 'components';

import { SelectContainer } from 'components/Header/style';

export const UpcomingGamesHeader = ({ leagues }) => {
  const dispatch = useDispatch();
  const { leaguesList, filteredLeagueList } = useGetSortedLeaguesList(leagues);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();

  const handleLeaguesChange = useCallback(
    (selectedLeague) => {
      const { value, label } = selectedLeague;

      if (championshipLeagues.includes(label)) {
        navigate(`/${ROUTES.CHAMPIONSHIPS}?league=${label}&leagueId=${value}`);
      } else if (label === TODAYS_GAMES_LABEL) {
        dispatch(allGamesResetAction());
        setSearchParams([]);
        navigate('/');
      } else {
        navigate(`/?league=${label}&leagueId=${value}`);
      }
    },
    [dispatch, navigate, setSearchParams]
  );

  const handleLeagueForTeamChange = useCallback(
    (selectedLeague) => {
      const { value, label } = selectedLeague;

      setSearchParams(
        (prevValue) => {
          prevValue.set('league', label);
          prevValue.set('leagueId', value);
          return prevValue;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  useEffect(() => {
    setSearchParams(
      (prevValue) => {
        if (params?.league && params?.leagueId) {
          prevValue.delete('league');
          prevValue.delete('leagueId');
        }
        return prevValue;
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectContainer>
      <Selector
        isDisabled={!leaguesList?.length}
        onChange={handleLeaguesChange}
        options={leaguesList}
        value={UPCOMING_GAMES_ITEM}
      />

      <Selector
        isDisabled={!filteredLeagueList?.length}
        onChange={handleLeagueForTeamChange}
        options={filteredLeagueList}
        placeholder="Select League..."
        value={createSelectedValue({
          value: params?.leagueId,
          label: params?.league,
        })}
      />
    </SelectContainer>
  );
};
