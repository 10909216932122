import { backgroundNames } from 'constants/backgroundNames';
import { UPCOMING_GAMES_LABEL } from 'constants/names.js';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getBackgroundName, generatePageTitle } from 'helpers';
import { changeFavicon, changeFaviconTeam } from 'helpers/changeFavicon';
import { BackgroundImg } from './style.js';

export const ThemeProvider = ({ children, eventLeague }) => {
  const [searchParams] = useSearchParams();
  const league = searchParams.get('league');
  const collegeLeague = searchParams.get('collegeLeague');
  const { data: configuration } = useSelector((state) => state.configuration);
  const [thisEventLeague, setThisEventLeague] = useState('');

  //** CHANGE BACKGROUND FUNCTION */
  const loadBackgroundImage = useCallback((imgName) => {
    let imageName = '';

    if (imgName) {
      imageName = imgName;
    }

    if (imageName !== '') {
      import(`assets/img/${imageName}.jpg`)
        .then((image) => {
          setBackground(image.default);
        })
        .catch((error) => {
          loadBackgroundImage(backgroundNames.default);
          console.log(error);
        });
    } else {
      loadBackgroundImage(backgroundNames.default);
    }
  }, []);

  const [background, setBackground] = useState('');

  const backgroundImageRef = useRef();

  if (configuration?.display_one_team === '1') {
    //** one team display */
    changeFaviconTeam(configuration?.one_team_name);
  } else if (configuration?.display_teams_select === '1') {
    //** one league display */
    changeFavicon('favicon');
  } else if (configuration?.display_leagues_select === '1') {
    //** display all leagues and teams selection */
    changeFavicon('favicon');
  }

  //** INITIALLY SET BACKGROUND FOR DOWNKOUNT SITE **//
  /*
  useEffect(() => {
    if (!league) {
      setBackground(
        loadBackgroundImage(
          configuration?.display_leagues_select === '1'
            ? backgroundNames.default
            : ''
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration?.display_leagues_select]);
  */

  //** SET BACKGROUND DEPENDS UPON LEAGUE SELECTED - USE DEFAULT OTHERWISE **//
  useEffect(() => {
    let tempLeagueName = '';
    if (league && league != UPCOMING_GAMES_LABEL) {
      tempLeagueName = league?.toUpperCase();
    } else if (collegeLeague && collegeLeague != UPCOMING_GAMES_LABEL) {
      tempLeagueName = collegeLeague?.toUpperCase();
    } else if (thisEventLeague) {
      tempLeagueName = thisEventLeague?.toUpperCase();
    }
    loadBackgroundImage(getBackgroundName(tempLeagueName));
  }, [league, collegeLeague, loadBackgroundImage, thisEventLeague]);

  //** FUNCTION TO SET PAGE TITLE AT THE BROWSER WINDOW AND META DESCRIPTION **//
  useEffect(() => {
    if (
      configuration?.site_name &&
      configuration?.sport_level &&
      configuration?.site_description &&
      configuration?.sport_type
    ) {
      generatePageTitle({
        title: `${configuration.site_name} - ${window.location.origin}`,
        metaDescription: `${configuration.site_description ? configuration.site_description : 'The Countdown Continues...'}`,
        //metaDescription: `${configuration.sport_level} ${configuration.sport_type} league game schedule`,
      });
    }
  }, [
    configuration?.site_name,
    configuration?.sport_type,
    configuration?.sport_level,
    configuration?.site_description,
  ]);

  //** SET BACKGROUND FOR ONE TEAM SITES **//
  useEffect(() => {
    if (configuration?.display_one_team === '1') {
      loadBackgroundImage(
        getBackgroundName(configuration?.one_league_name?.toUpperCase())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration?.display_one_team, configuration?.one_league_name]);

  //** SET EVENT LEAGUE NAME AND LOAD BACKGROUND **//
  useEffect(() => {
    if (thisEventLeague != eventLeague) {
      let shouldResetBackground = false;

      // Check if background image will in fact differ ... ex, ncaam -> ncaaw (both basketball)
      if (
        getBackgroundName(eventLeague?.toUpperCase()) !=
        getBackgroundName(thisEventLeague?.toUpperCase())
      ) {
        shouldResetBackground = true;
      }

      // Set this event league regardless if it differs
      setThisEventLeague(eventLeague);

      // Only reset if background image differs, ex moving from ncaam (basketball) -> nfl (football)
      if (shouldResetBackground) {
        // Get ref of background and force reset animation by setting to none/null
        if (backgroundImageRef && backgroundImageRef.current) {
          backgroundImageRef.current.style.animation = 'none';
          backgroundImageRef.current.offsetHeight; /* trigger reflow */
          backgroundImageRef.current.style.animation = null;
        }

        loadBackgroundImage(getBackgroundName(eventLeague?.toUpperCase()));
      }
    }

    // NOTE: Calling this here seems to cause the background to refresh even when the same, ie basketball -> basketball
    // setThisEventLeague(eventLeague);
    // loadBackgroundImage(getBackgroundName(eventLeague?.toUpperCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundImageRef, setThisEventLeague, eventLeague]);

  return (
    <>
      {background ? (
        <BackgroundImg $imgUrl={background} ref={backgroundImageRef} />
      ) : null}
      {children}
    </>
  );
};
