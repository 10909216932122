import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { validateEmail } from 'helpers';
import { toast } from 'react-toastify';
import { api } from 'api/api';
import { getAnalytics, logEvent } from 'firebase/analytics';

import CustomInput from 'components/CustomInput';
import Button from 'components/Button';
import Link from 'components/Link';
import { BackIcon } from 'assets/icons';

import { LinksContainer, ArrowWrapper } from '../style.js';

const RestorePasswordForm = () => {
  const analytics = getAnalytics();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const sendRestoreEmail = useMutation((data) => api.user.resetPassword(data), {
    onError: () => {
      toast.error(
        'An error occurred while restoring Your password. Please, make sure that You entered correct email or try again later.'
      );

      logEvent(analytics, 'select_content', {
        content_type: 'error',
        content_id: `An error occurs during restoring password for user with email ${email}. Site url: ${window.location.href}`,
      });
    },
    onSuccess: () => {
      toast.success(`Recovery password instructions were sent to ${email}`);
      setEmail('');
    },
  });

  const emailInputHandler = useCallback(
    (inputValue) => {
      setEmail(inputValue);
      if (inputValue !== '' && !validateEmail(email)) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
    },
    [email]
  );

  const emailErrorHandler = useCallback(() => {
    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  }, [email]);

  const restorePasswordHandler = useCallback(() => {
    if (email && !emailError) {
      sendRestoreEmail.mutate({ email });
    }
  }, [email, emailError, sendRestoreEmail]);

  const isButtonDisabled = !email || emailError;

  return (
    <>
      <CustomInput
        isError={emailError}
        onBlur={emailErrorHandler}
        onChange={emailInputHandler}
        placeholder="Email"
        type="email"
        value={email}
      />
      <LinksContainer>
        <Link route="/" title="Return">
          <ArrowWrapper>
            <BackIcon />
          </ArrowWrapper>
        </Link>
      </LinksContainer>
      <Button
        disabled={isButtonDisabled || sendRestoreEmail.isLoading}
        onClick={restorePasswordHandler}
        title="Reset password"
      />
    </>
  );
};

export default RestorePasswordForm;
