import { ROUTES } from 'constants/routes.js';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';

import EventDate from 'components/GameDate/EventDate.jsx';
import { Teams, TvChannel, GameResult } from 'components';

import 'swiper/swiper-bundle.css';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

export const DashboardCustomEvents = ({ events, currentEvent }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeId = searchParams.get('typeId');
  const type = searchParams.get('type');

  const [progress, setProgress] = useState(currentEvent);

  //** FIND event_id FUNCTION */
  function slideChanged(index) {
    const oneGame = events[index]?.event_id;
    if (oneGame) {
      setProgress(oneGame);
    }
  }

  //** URL CHANGE */
  useEffect(() => {
    navigate(
      `/${ROUTES.CUSTOM_EVENTS}?type=${type}&typeId=${typeId}&eventId=${progress}`
    );
  }, [navigate, progress, type, typeId]);

  return (
    <>
      <LeftSideBar>
        <div className="swiper-button-prev-unique" />
      </LeftSideBar>
      <StyledSection>
        <Swiper
          grabCursor={true}
          id="main-slider"
          initialSlide={progress}
          modules={[Navigation, Mousewheel]}
          mousewheel={true}
          navigation={{
            prevEl: '.swiper-button-prev-unique',
            nextEl: '.swiper-button-next-unique',
          }}
          onSlideChange={(swiper) => {
            slideChanged(swiper.activeIndex);
          }}
          slidesPerView={1}
          tag="section"
          wrapperTag="ul"
        >
          {events.map((event) => (
            <SwiperSlide key={event.event_id} tag="li">
              <SlideContent>
                <Teams team={event.event_text} />
                <EventDate eventTime={event.event_start} />
                <TvChannel channel={event.event_tv_channel} />
                <GameResult date={event.event_start} />
              </SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSection>
      <RightSideBar>
        <div className="swiper-button-next-unique" />
      </RightSideBar>{' '}
    </>
  );
};
