import { DEFAULT_TOAST_OPTIONS } from 'constants/names.js';
import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import Modal from 'components/Modal';
import { SecondaryLoader } from 'components/Loading/SecondaryLoader';
import { MapWrapper } from 'components/GoogleMap/style';
import {
  ErrorContainer,
  GameInfoLinkContainer,
  DirectionButton,
} from '../style';

export const MapWithDirection = ({
  onClose,
  stadium,
  coordinatesStatus,
  coordinatesIsLoading,
  isLoading,
  city,
  location,
  show,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const getDirection = useCallback(() => {
    setIsButtonDisabled(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast.warning(
        'Please, make sure that geolocation is supported by this browser.',
        DEFAULT_TOAST_OPTIONS
      );
    }

    function showPosition(position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      if (!lat && !long) {
        toast.warning(
          'Please, turn on geolocation on your browser.',
          DEFAULT_TOAST_OPTIONS
        );
      }
      window.open(
        `https://www.google.com/maps/dir/${lat},${long}/${location.lat}+${location.lng}`
      );
      setIsButtonDisabled(false);
    }
  }, [location.lat, location.lng]);

  if (coordinatesIsLoading) {
    return (
      <MapWrapper>
        <SecondaryLoader />
      </MapWrapper>
    );
  }

  return (
    <Modal onClose={onClose} show={show}>
      {coordinatesStatus === 'error' || (!location.lat && !location.lng) ? (
        <MapWrapper>
          <ErrorContainer>
            Stadium location is currently unavailable.
          </ErrorContainer>
        </MapWrapper>
      ) : (
        <GoogleMap location={location} />
      )}

      <GameInfoLinkContainer>
        {!isLoading && location.lat && location.lng ? (
          <DirectionButton disabled={isButtonDisabled} onClick={getDirection}>
            Get directions to {stadium}
            {stadium ? ' in ' : ''} {city}...
          </DirectionButton>
        ) : null}
      </GameInfoLinkContainer>
    </Modal>
  );
};
