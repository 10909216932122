import React, { useState, useRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { color } from 'theme';

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
  outline: none !important;
`;

const StyledSelect = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'transparent',
    borderBottom: `1px solid ${color.red}`,
    borderRadius: 'none',
    maxWidth: '250px',
    minWidth: '200px',
    width: '50%',
    color: '#ffffff',
    marginBottom: 'none',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none',
    opacity: isDisabled ? 0.5 : 1,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#000000' : '#ffffff',
    backgroundColor:
      isFocused || isSelected ? `${color.red}` : 'rgba(0, 0, 0, 0.9)',
    pointerEvents: isSelected ? 'none' : 'auto',
  }),
};

const selectorColors = {
  primary: 'transparent',
  primmary25: 'transparent',
};

const SelectTitles = styled.span`
  color: ${color.greyText};
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-right: 1rem;
  font-weight: 300;
`;

export const Selector = ({
  isDisabled = false,
  placeholder = 'Select',
  options,
  //defaultValue,
  onChange,
  value,
  title = '',
}) => {
  const [isSearch, setIsSearch] = useState(true);

  const ref = useRef();

  function handleOnFocus(thisRef) {
    setTimeout(() => {
      const { focusedOptionRef } = thisRef.current;
      let selectedNode = focusedOptionRef;

      if (focusedOptionRef) {
        if (value) {
          if (value.label != focusedOptionRef.innerHTML) {
            //console.log('value.label and focusedOptionRef.innerHTML do not match');

            let parentElement = focusedOptionRef.parentElement;

            parentElement.childNodes.forEach((node) => {
              if (value.label == node.innerHTML) {
                selectedNode = node;
              }
            });
          }
        }
      }

      //console.dir(selectedNode);
      selectedNode && selectedNode.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  }

  /*
  function getSelectValue() {
    setTimeout(() => {
      value;
    }, 1);
  }
    */

  // function handleMenuOpen(thisRef) {
  //   setTimeout(() => {
  //     console.log(thisRef.current);
  //     const { focusedOptionRef } = thisRef.current;
  //     focusedOptionRef &&
  //       focusedOptionRef.scrollIntoView({ behavior: 'smooth' });
  //   }, 0.1);

  //   //setIsSearch(true);
  // }

  return (
    <StyledLabel>
      {title ? <SelectTitles>Team</SelectTitles> : null}
      <Select
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        blurInputOnSelect={true}
        components={{ IndicatorSeparator: () => null }}
        defaultValue={value}
        //defaultValue={defaultValue}
        isDisabled={isDisabled}
        isSearchable={isSearch}
        menuShouldScrollIntoView={false}
        onChange={onChange}
        onFocus={() => handleOnFocus(ref)}
        onMenuClose={() => setIsSearch(false)}
        onMenuOpen={() => setIsSearch(true)}
        //onMenuOpen={() => handleMenuOpen(ref)}
        options={options}
        placeholder={placeholder}
        ref={ref}
        styles={StyledSelect}
        theme={{
          colors: selectorColors,
        }}
        value={value}
        //value={getSelectValue()}
      />
    </StyledLabel>
  );
};
