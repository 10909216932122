import React from 'react';

import { SecondaryText } from 'theme/titles';
import { EndedGamesCont } from './style';

const EndedGame = ({ result, isGameInProgress }) => (
  <EndedGamesCont>
    {result === '' ? (
      <SecondaryText>
        {isGameInProgress ? 'Event in progress' : 'Event Concluded'}
      </SecondaryText>
    ) : (
      <SecondaryText>{result}</SecondaryText>
    )}
  </EndedGamesCont>
);

export default EndedGame;
