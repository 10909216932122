import moment from 'moment';

const now = moment.utc(new Date().getTime()).valueOf();

export const getLatestNews = (newsArray) => {
  if (!newsArray) return;
  let _latestNews;
  const _currentNewsIndex = newsArray.findIndex(
    (i) => moment.utc(i.news_updated, 'YYYY-MM-DD HH:mm:ss').valueOf() >= now
  );

  _latestNews =
    _currentNewsIndex === -1 ? newsArray.length - 1 : _currentNewsIndex;
  return _latestNews;
};
