import styled from 'styled-components';
import { color } from 'theme';

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.$show ? 'visible' : 'none')};
  z-index: 998;
`;

export const ModalContainer = styled.div`
  overflow: hidden;
  max-width: 600px;
  width: 96%;
  height: 480px;
  border: 2px solid ${color.red};
  border-radius: 14px;
  background: rgba(0, 0, 0, 1);
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: ${(props) =>
    props.$show ? 'translateY(0)' : 'translateY(-1000px)'};
  opacity: ${(props) => (props.$show ? 1 : 0)};
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
`;

export const InnerContainer = styled.div`
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 1);
  inset: 2px;
  border-radius: 14px;
  padding: 20px 10px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
