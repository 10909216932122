import React from 'react';

import { FormTitle, Container } from './style.js';

export const AuthWrapper = ({ title, children }) => (
  <>
    <FormTitle>{title}</FormTitle>
    <Container>{children}</Container>
  </>
);
