import React from 'react';

import { LargeText } from 'theme/titles';
import { TeamWrapper, StyledVersus } from './style';

export const Teams = ({ team, opponent, location, eventType }) => (
  <TeamWrapper>
    {eventType !== '5' ? <LargeText>{team}</LargeText> : null}

    {opponent && eventType !== '5' ? (
      <StyledVersus>
        {location === '0' || location === '2' ? 'VS' : 'AT'}
      </StyledVersus>
    ) : null}
    <LargeText>{opponent}</LargeText>
  </TeamWrapper>
);
