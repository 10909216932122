import styled from 'styled-components';

import { device, color } from 'theme';

export const Star = styled.div`
  position: relative;
  background: ${(props) => (props.$isFavorite ? color.red : color.greyText)};
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${(props) => !props.$isFavorite && color.red};
  }

  &::after {
    display: ${(props) => (props.$isFavorite ? 'auto' : 'none')};
    position: absolute;
    content: '';
    top: 15px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
  }

  @media ${device.tablet} {
    height: 22px;
    width: 22px;
  }
`;
