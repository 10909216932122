import styled from 'styled-components';
import { device } from 'theme';

export const NewsSlideWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  @media ${device.laptop} {
    width: 75%;
  }
`;
