import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api/api';
import types from '../types';

function* getTeams({ payload }) {
  try {
    const { status, data } = yield call(api.teams.getTeams, payload);
    if (status < 200 || status >= 300) {
      yield put({
        type: types.GET_TEAMS_ERROR,
        payload:
          "Something went wrong. There's an issue on our end and we can't show teams list. Please try again later",
      });
    } else {
      yield put({ type: types.GET_TEAMS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: types.GET_TEAMS_ERROR,
      payload:
        "Something went wrong. There's an issue on our end and we can't show teams list. Please try again later",
    });
  }
}

export function* teamsWatcher() {
  yield takeLatest(types.GET_TEAMS_START, getTeams);
}
