import styled from 'styled-components';
import { CloseRoundButton } from 'components/Button/style.js';
import { color, device } from 'theme';
import { Text } from 'theme/titles';

export const SlidePanelContainer = styled.div`
  height: 100%;
  background: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  padding: 10px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-out;

  @media ${device.tablet} {
    width: 75%;
  }

  @media ${device.laptop} {
    width: 25%;
  }
`;

export const CloseButton = styled(CloseRoundButton)`
  margin: 15px 0 0 15px;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
  top: 0;
  right: 0;
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
`;

export const FooterNotification = styled(Text)`
  color: ${color.red};
  margin-top: 50px;
`;

export const LogoutContainer = styled.div`
  width: fit-content;
  margin: 50px auto 0 auto;
`;
