/* eslint-disable sort-keys */
const types = {
  // LEAGUES
  GET_LEAGUES_START: 'GET_LEAGUES_START',
  GET_LEAGUES_SUCCESS: 'GET_LEAGUES_SUCCESS',
  GET_LEAGUES_ERROR: 'GET_LEAGUES_ERROR',

  // TEAMS
  GET_TEAMS_START: 'GET_TEAMS_START',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  GET_TEAMS_ERROR: 'GET_TEAMS_ERROR',
  GET_TEAMS_RESET: 'GET_TEAMS_RESET',

  // GAMES
  GET_GAMES_START: 'GET_GAMES_START',
  GET_GAMES_SUCCESS: 'GET_GAMES_SUCCESS',
  GET_GAMES_ERROR: 'GET_GAMES_ERROR',
  GET_GAMES_RESET: 'GET_GAMES_RESET',

  // CURRENT GAME
  GET_CURRENT_GAME: 'GET_CURRENT_GAME',

  // CONFIGURATION
  GET_CONFIG_START: 'GET_CONFIG_START',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_ERROR: 'GET_CONFIG_ERROR',
  SET_CONFIG_DATA: 'SET_CONFIG_DATA',

  // ONE SELECTED TEAM
  SET_SELECTED_TEAM: 'SET_SELECTED_TEAM',

  // ONE SELECTED LEAGUE
  SET_SELECTED_LEAGUE: 'SET_SELECTED_LEAGUE',

  // AUTH USER - LOGIN
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_LOGIN_SET: 'USER_LOGIN_SET',
  USER_LOGIN_RESET: 'USER_LOGIN_RESET',

  // AUTH USER - SIGNUP
  USER_SIGNUP_START: 'USER_SIGNUP_START',
  USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
  USER_SIGNUP_ERROR: 'USER_SIGNUP_ERROR',

  // FAVORITE TEAMS
  GET_FAVORITE_TEAMS_SUCCESS: 'GET_FAVORITE_TEAMS_SUCCESS',
  GET_FAVORITE_TEAMS_ERROR: 'GET_FAVORITE_TEAMS_ERROR',

  // TODAYS GAMES
  GET_TODAYS_GAMES_SUCCESS: 'GET_TODAYS_GAMES_SUCCESS',
};

export default types;
