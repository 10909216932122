import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { saveFirebaseToken } from 'api/api';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBsSWkk074ULdv9Pg7uT8WWyJ26Q2ajulg',
  authDomain: 'downkount-app.firebaseapp.com',
  projectId: 'downkount-app',
  storageBucket: 'downkount-app.appspot.com',
  messagingSenderId: '1007421795916',
  appId: '1:1007421795916:web:ad931701a7775724254ee9',
  measurementId: 'G-FM5Y3GECX1',
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export const fetchToken = ({ userId, siteUrl }) => {
  return getToken(messaging, {
    //vapidKey: 'BBXbOC6L5TFQhAgw0OcD0rdeLYl4dRvAC-lt4qFQ4ci_yGWQJrfuOoGuA2co6NBa2pgppNastCHTxIXzx3uRY8Y'
    vapidKey:
      'BFsOM7wMLRY8IHWoepHvYlP0Sv_-co68vRilt48CKQITDQQHkiHb960LCyoXUnmh-Hat2muDjoRQhr0x7N2CEuM',
  })
    .then((currentToken) => {
      if (currentToken && userId) {
        saveFirebaseToken({
          siteUrl: `${siteUrl}`,
          firebaseToken: `${currentToken}`,
          userId: userId,
        });
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token.');
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
