import styled from 'styled-components';

import { device } from 'theme';

export const BodyContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  animation: appearance 1.2s ease-in;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: ${(props) =>
      props.$backgroundColor
        ? `linear-gradient(0deg, rgba(36, 36, 36, 0.45) 10%, ${props.$backgroundColor} 99%)`
        : 'transparent'};
    transition: 1.2s ease-in;
    animation: appearance 1.2s ease-in;
  }

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  min-height: 150px;
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0;

  @media ${device.laptop} {
    min-height: 120px;
    padding: 0;
  }
`;

export const StyledFooter = styled.footer`
  min-height: 150px;
  animation: appearance 1.4s ease-in;

  @media ${device.laptop} {
    min-height: 120px;
  }

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const BackgroundImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: url(${(props) => props.$imgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: appearance 1.2s ease-in;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
