import types from '../types';

const initialState = [];

const games = (
  state = { loading: false, data: initialState, current: null, error: null },
  action
) => {
  switch (action.type) {
    case types.GET_GAMES_START:
      return { ...state, error: null, loading: true };
    case types.GET_GAMES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        current: null,
        error: null,
      };
    case types.GET_GAMES_ERROR:
      return {
        loading: false,
        data: initialState,
        current: null,
        error: action.payload,
      };
    case types.GET_CURRENT_GAME:
      return { ...state, current: action.payload };

    case types.GET_GAMES_RESET:
      return {
        loading: false,
        data: initialState,
        current: null,
        error: null,
      };

    default:
      return state;
  }
};

export default games;
