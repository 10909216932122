import {
  DEFAULT_TOAST_OPTIONS,
  UPCOMING_GAMES_LABEL,
} from 'constants/names.js';
// TEMP - Don't show login until later...
import { ROUTES } from 'constants';
//import { ROUTES, SITES_WITH_AUTH } from 'constants';
import React, { useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onMessageListener } from 'firebase.js';
import { hexToRgba, isEmptyObject } from 'helpers';
import { getAllTeamsAction } from 'redux/teams/actionCreators.js';
import {
  getAllGamesAction,
  allGamesResetAction,
} from 'redux/games/actionCreators.js';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { api } from 'api/api';
import types from 'redux/types';

// TEMP: Don't show news feed
// TODO: Update news more regularly and determine if should use espn news or custom news, etc

import {
  Header,
  ChoiceTitle,
  AppStoreLinks,
  ErrorMessage,
  // NewsFeed,
  NotificationComponent,
  Loading,
  AuthContainer,
} from 'components';
import Dashboard from 'components/Dashboard';
import DashboardOneTeam from 'components/Dashboard/DashboardOneTeam';
import img from 'assets/img/favicon.ico';

import 'react-toastify/dist/ReactToastify.css';
import {
  BodyContainer,
  MainContainer,
  StyledHeader,
  StyledFooter,
} from 'components/App/style';

// TEMP - Don't show login until later...
const shouldDisplayAuth = false;

//const hostName = window.location.hostname;
//const shouldDisplayAuth = SITES_WITH_AUTH.includes(hostName);

const MainPage = ({ eventLeague, setEventLeague }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const memoizedGamesState = createSelector(
    [(state) => state?.games],
    (games) => ({
      games: games.data,
      loading: games.loading,
      error: games.error,
      current: games.current,
    })
  );

  const memoizedConfigState = createSelector(
    [(state) => state.configuration],
    (configuration) => ({
      data: configuration.data,
      loading: configuration.loading,
      error: configuration.error,
    })
  );

  const {
    games,
    loading: isGamesLoading,
    current: currentGame,
    error: gamesError,
  } = useSelector(memoizedGamesState);

  const {
    loading: isConfigLoading,
    error: isConfigError,
    data: configData,
  } = useSelector(memoizedConfigState);

  const todaysGames = useSelector((state) => state.todaysGames.data);

  onMessageListener()
    .then((payload) => {
      toast(
        <NotificationComponent
          body={payload.notification.body}
          img={payload.notification.icon ?? window.location.origin + img}
          title={payload.notification.title}
        />,
        DEFAULT_TOAST_OPTIONS
      );
    })
    .catch(() => {
      toast.error("It seams we can't show game notification right now");

      logEvent(analytics, 'select_content', {
        content_type: 'error',
        content_id: `Unable to show notification for ${window.location.href}`,
      });
    });

  useEffect(() => {
    if (configData && configData?.display_custom_events === '1') {
      navigate(`/${ROUTES.CUSTOM_EVENTS}`);
    }
  }, [configData, navigate]);

  useEffect(() => {
    if (
      configData?.display_leagues_select === '0' &&
      configData?.display_one_team === '0' &&
      configData?.display_teams_select === '0'
    ) {
      navigate(
        `/${
          ROUTES.CHAMPIONSHIPS
        }?league=${configData?.one_league_name.toLowerCase()}&leagueId=${
          configData?.one_league_id
        }`
      );
    }
  }, [configData, navigate]);

  useEffect(() => {
    if (
      params &&
      params?.leagueId &&
      configData?.display_teams_select !== '1'
    ) {
      dispatch(allGamesResetAction());
      dispatch(getAllTeamsAction(params?.leagueId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.leagueId]);

  useEffect(() => {
    if (
      params &&
      params?.teamId &&
      configData?.display_one_team !== '1' &&
      !params?.collegeLeagueId &&
      params.team !== UPCOMING_GAMES_LABEL
    ) {
      dispatch(getAllGamesAction(params?.teamId));
    } else if (
      configData?.display_teams_select !== '1' &&
      params.team === UPCOMING_GAMES_LABEL
    ) {
      api.games
        .getUpcomingGames({ leagueId: params?.leagueId })
        .then(({ data }) => {
          dispatch({ type: types.GET_GAMES_SUCCESS, payload: data });
          dispatch({ type: types.GET_CURRENT_GAME, payload: 0 });
        })
        .catch(() => {
          dispatch({
            type: types.GET_GAMES_ERROR,
            payload:
              'Something went wrong. It seems there is no info about this team schedule. Please try again later',
          });
        });
    }
    // eslint-disable-next-line
  }, [params?.teamId]);

  useEffect(() => {
    if (
      configData?.display_one_team === '1' &&
      configData.sport_level !== 'Collegiate'
    ) {
      dispatch(getAllGamesAction(configData?.one_team_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData?.display_one_team, configData?.one_team_id]);

  useEffect(() => {
    if (configData?.display_teams_select === '1') {
      dispatch(getAllTeamsAction(configData.one_league_id));
      api.games
        .getUpcomingGames({ leagueId: configData.one_league_id })
        .then(({ data }) => {
          dispatch({ type: types.GET_GAMES_SUCCESS, payload: data });
          dispatch({ type: types.GET_CURRENT_GAME, payload: 0 });
        })
        .catch(() => {
          dispatch({
            type: types.GET_GAMES_ERROR,
            payload:
              'Something went wrong. It seems there is no info about this team schedule. Please try again later',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData?.display_teams_select === '1']);

  const shouldDisplayMainDashboard = useMemo(
    () =>
      configData?.display_leagues_select === '1' &&
      configData?.display_one_team === '0' &&
      configData?.display_teams_select === '0' &&
      configData?.display_custom_events === '0',
    [
      configData?.display_custom_events,
      configData?.display_leagues_select,
      configData?.display_one_team,
      configData?.display_teams_select,
    ]
  );

  const shouldDisplayOneLeagueMainDashboard = useMemo(
    () =>
      configData?.display_teams_select === '1' &&
      configData?.display_leagues_select === '0' &&
      configData?.display_one_team === '0',
    [
      configData?.display_leagues_select,
      configData?.display_one_team,
      configData?.display_teams_select,
    ]
  );

  if (gamesError) {
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unable to show games schedule  for ${window.location.href}`,
    });
  }

  if (isConfigLoading) return <Loading />;

  if (!isConfigLoading && !!isConfigError) {
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unable to load site configuration for ${window.location.href}`,
    });

    return <ErrorMessage />;
  }

  const isNotSelectionMade =
    configData.display_leagues_select === '1' && isEmptyObject(params);

  return (
    <BodyContainer
      $backgroundColor={
        configData?.display_one_team === '1' &&
        Boolean(configData?.style_main_color)
          ? hexToRgba(configData?.style_main_color)
          : null
      }
    >
      <StyledHeader>
        {shouldDisplayAuth ? <AuthContainer /> : null}
        <Header setEventLeague={setEventLeague} />
      </StyledHeader>
      <MainContainer>
        {configData?.display_one_team !== '1' &&
        !isNotSelectionMade &&
        (!params?.league || !params?.team) ? (
          <ChoiceTitle
            title={
              configData?.display_teams_select === '1' && !params?.team
                ? 'Select Team'
                : !params?.league
                  ? 'Select League'
                  : 'Select Team'
            }
          />
        ) : null}

        {isGamesLoading ? <Loading /> : null}

        {!isGamesLoading && gamesError ? (
          <ErrorMessage title="Unable to display events. Please select a different team or try again later." />
        ) : null}

        {isNotSelectionMade ? (
          <Dashboard
            currentGame={0}
            games={todaysGames}
            setEventLeague={setEventLeague}
          />
        ) : null}

        {shouldDisplayMainDashboard &&
        Boolean(games) &&
        currentGame !== null ? (
          <Dashboard currentGame={currentGame} games={games} />
        ) : null}

        {shouldDisplayOneLeagueMainDashboard &&
        Boolean(games) &&
        currentGame !== null ? (
          <Dashboard currentGame={currentGame} games={games} />
        ) : null}

        {configData?.display_one_team === '1' &&
        Boolean(games) &&
        currentGame !== null ? (
          <DashboardOneTeam
            configuration={configData}
            currentGame={currentGame}
            eventLeague={eventLeague}
            games={games}
            setEventLeague={setEventLeague}
          />
        ) : null}
      </MainContainer>

      <StyledFooter>
        {/* <NewsFeed /> */}
        <AppStoreLinks />
      </StyledFooter>
    </BodyContainer>
  );
};

export default MainPage;
