import React from 'react';

import { StyledButton } from './style.js';

const Button = ({
  title,
  onClick,
  disabled = false,
  withIcon = false,
  icon,
  before,
}) => (
  <StyledButton disabled={disabled} onClick={onClick}>
    {withIcon && before ? icon : null}
    {title}
    {withIcon && !before ? icon : null}
  </StyledButton>
);

export default Button;
