import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api/api';
import getIndex from 'helpers/getIndex';
import types from '../types';

function* getSchedule({ payload }) {
  try {
    const { status, data } =
      typeof payload === 'object'
        ? yield call(api.games.getCollegeSchedule, payload)
        : yield call(api.games.getSchedule, payload);
    if (status < 200 || status >= 300) {
      yield put({
        type: types.GET_GAMES_ERROR,
        payload:
          "Something went wrong. There's an issue on our end and we can't show this team schedule. Please try again later",
      });
    }
    if (data.length === 0) {
      yield put({
        type: types.GET_GAMES_ERROR,
        payload:
          'Something went wrong. It seems there is no info about this team schedule. Please try again later',
      });
    } else {
      const gameIndex = yield call(getIndex, data);
      yield put({ type: types.GET_GAMES_SUCCESS, payload: data });
      yield put({ type: types.GET_CURRENT_GAME, payload: gameIndex });
    }
  } catch (error) {
    yield put({
      type: types.GET_GAMES_ERROR,
      payload:
        "Something went wrong. There's an issue on our end and we can't show this team schedule. Please try again later",
    });
  }
}

export function* gamesWatcher() {
  yield takeLatest(types.GET_GAMES_START, getSchedule);
}
