import types from '../types';

const teams = (state = { loading: false, data: null, error: null }, action) => {
  switch (action.type) {
    case types.GET_TEAMS_START:
      return { ...state, error: null, loading: true };
    case types.GET_TEAMS_SUCCESS:
      return { loading: false, error: null, data: action.payload };
    case types.GET_TEAMS_ERROR:
      return { loading: false, data: null, error: action.payload };
    case types.GET_TEAMS_RESET:
      return { loading: false, data: action.payload, error: null };

    default:
      return state;
  }
};

export default teams;
