import styled from 'styled-components';

import { device, color } from 'theme';
import { LargeText } from 'theme/titles';

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  animation: appearance 1s ease-in;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const StyledVersus = styled(LargeText)`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  color: ${color.red};

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 3rem;
  }
`;
