import React from 'react';

import styles from './style.module.css';

export const SecondaryLoader = () => (
  <p className={styles.round}>
    <span className={`${styles.ouro} ${styles.ouro3}`}>
      <span className={styles.left}>
        <span className={styles.anim} />
      </span>
      <span className={styles.right}>
        <span className={styles.anim} />
      </span>
    </span>
  </p>
);
