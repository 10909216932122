import styled from 'styled-components';
import { color, device } from 'theme';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.isCentered ? 'center' : 'space-between'};
  max-width: 300px;
  width: 100%;
  z-index: 100;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    max-width: 600px;
  }
`;

export const StyledSelect = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'transparent',
    borderBottom: `1px solid ${color.red}`,
    borderRadius: 'none',
    maxWidth: '250px',
    minWidth: '200px',
    width: '50%',
    color: '#ffffff',
    marginBottom: 'none',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#000000' : '#ffffff',
    backgroundColor:
      isFocused || isSelected ? `${color.red}` : 'rgba(0, 0, 0, 0.9)',
    pointerEvents: isSelected ? 'none' : 'auto',
  }),
};

export const StyledSelectTeam = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'transparent',
    borderBottom: `1px solid ${color.red}`,
    borderRadius: 'none',
    maxwidth: '250px',
    minWidth: '200px',
    width: '50%',
    color: '#ffffff',
    marginBottom: 'none',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#000000' : '#ffffff',
    backgroundColor:
      isFocused || isSelected ? `${color.red}` : 'rgba(0, 0, 0, 0.9)',
    pointerEvents: isSelected ? 'none' : 'auto',
  }),
};

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
  outline: none !important;
`;

export const SelectTitles = styled.span`
  color: ${color.greyText};
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-right: 1rem;
  font-weight: 300;
`;

export const BackgroundImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: url(${(props) => props.$imgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: appearance 1.2s ease-in;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const selectorColors = {
  primary: 'transparent',
  primmary25: 'transparent',
};
