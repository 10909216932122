import React from 'react';
import moment from 'moment';
import Modal from 'components/Modal';
import { useGetWeather } from 'hooks';

import { SecondaryLoader } from 'components/Loading/SecondaryLoader';
import { WeatherSectionTitle, ErrorContainer } from '../style';
import { WeatherGameInfo } from './WeatherGameInfo';

export const WeatherInfoModal = ({ date, show, onClose, zipCode }) => {
  const { status, data, isLoading } = useGetWeather({ zipCode, show });

  return (
    <Modal onClose={onClose} show={show}>
      <WeatherSectionTitle>
        Weather forecast for {moment(date).format('DD')} of{' '}
        {moment(date).format('MMMM')}
      </WeatherSectionTitle>

      {!zipCode ? (
        <ErrorContainer>
          Weather forecast for game day is currently unavailable.
        </ErrorContainer>
      ) : null}

      {isLoading ? <SecondaryLoader /> : null}
      {(!isLoading && status === 'error') || (!isLoading && !date) ? (
        <ErrorContainer>
          Weather forecast for game day is currently unavailable.
        </ErrorContainer>
      ) : null}

      {!isLoading && data ? <WeatherGameInfo data={data} date={date} /> : null}
    </Modal>
  );
};
