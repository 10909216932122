import React from 'react';
import { CustomLink } from './style.js';

const Link = ({ title, route, children }) => (
  <CustomLink to={route}>
    {title}
    {children}
  </CustomLink>
);

export default Link;
