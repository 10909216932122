import React from 'react';

export const TransportIcon = () => (
  <svg
    id="Layer_1"
    style={{ enableBackground: 'new 0 0 100.353 100.353' }}
    version="1.1"
    viewBox="0 0 100.353 100.353"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M87.269,64.377l-18.396-29.15l7.767-12.133c1.726-2.692,2.413-5.855,1.841-8.463c-0.396-1.799-1.339-3.219-2.73-4.108
	l-0.43-0.278c-3.555-2.269-8.73-0.311-11.789,4.46l-7.3,11.41l-40.603-3.791c-0.561-0.051-1.101,0.212-1.403,0.686l-3.008,4.704
	c-0.248,0.387-0.304,0.866-0.154,1.299s0.491,0.775,0.925,0.926l34.188,11.887L33.274,61.989l-20.159,1.959
	c-0.526,0.052-0.986,0.375-1.211,0.854L10.31,68.18c-0.2,0.424-0.19,0.918,0.026,1.333c0.217,0.416,0.616,0.707,1.079,0.785
	l23.25,3.952l13.327,19.456c0.265,0.387,0.695,0.628,1.164,0.65c0.025,0.001,0.049,0.002,0.074,0.002
	c0.442,0,0.863-0.195,1.148-0.535l2.404-2.861c0.34-0.405,0.442-0.959,0.268-1.459l-6.668-19.127l12.563-19.634l23.049,20.264
	c0.328,0.288,0.764,0.421,1.199,0.358c0.433-0.061,0.817-0.308,1.054-0.675l3.017-4.701C87.577,65.498,87.579,64.87,87.269,64.377z
	 M82.663,67.601l-23.05-20.265c-0.328-0.289-0.761-0.418-1.2-0.359c-0.433,0.061-0.817,0.309-1.054,0.677L43.461,69.373
	c-0.248,0.388-0.304,0.868-0.153,1.303l6.628,19.01l-0.585,0.696L36.86,72.146c-0.112-0.163-0.255-0.303-0.421-0.411l-0.214-0.139
	c-0.171-0.111-0.363-0.186-0.564-0.22l-21.794-3.704l0.387-0.821l20.037-1.946c0.458-0.045,0.871-0.297,1.119-0.685l14.319-22.378
	c0.247-0.387,0.304-0.866,0.154-1.3c-0.15-0.434-0.491-0.775-0.925-0.926L14.768,27.729l1.492-2.333l40.603,3.791
	c0.566,0.054,1.102-0.213,1.403-0.685l7.792-12.179c2.079-3.244,5.58-4.873,7.631-3.561l0.448,0.29
	c0.89,0.568,1.26,1.522,1.413,2.222c0.406,1.847-0.131,4.165-1.437,6.202l-8.281,12.937c-0.313,0.49-0.315,1.117-0.005,1.609
	l18.395,29.15L82.663,67.601z"
      fill="#FFFFFF"
    />
  </svg>
);
