import styled from 'styled-components';

import { device } from 'theme';
import { SecondaryText } from 'theme/titles';

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${device.tablet} {
    margin-top: 2rem;
  }

  @media ${device.laptop} {
    margin-top: 0;
  }

  @media ${device.laptopL} {
    width: 50%;
  }
`;

export const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  animation: appearance 1s ease-in;
  padding: 1%;
  margin: 1%;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${device.tablet} {
    justify-content: space-evenly;
  }
`;

export const CountdowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const CountdowItemNumber = styled.span`
  color: #fff;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 2.5rem;

  @media ${device.tablet} {
    font-size: 4rem;
    line-height: 4rem;
  }
`;

export const CountdowItemDesc = styled.span`
  color: #bdbdbd;
  font-size: 0.5rem;
  line-height: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.1rem;
  }
`;

export const EndedGamesCont = styled.div`
  margin-top: 10px;
`;

export const TBDText = styled(SecondaryText)`
  margin-top: 10px;
`;
