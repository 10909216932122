import React, { useState, useCallback } from 'react';
import { EyeIcon } from 'assets/icons';

import {
  Input,
  InputWrapper,
  InputLabel,
  ErrorText,
  VisibleIconWrapper,
} from './style.js';

const CustomInput = ({
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  isError = false,
  isPassword = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleVisibility = useCallback(() => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }, [showPassword]);

  return (
    <InputWrapper>
      <Input
        $isError={isError}
        onBlur={onBlur}
        onInput={(e) => onChange(e.target.value)}
        required="required"
        type={isPassword && showPassword ? 'text' : type}
        value={value}
      />
      <InputLabel>{placeholder}</InputLabel>
      <i />
      {isPassword && value ? (
        <VisibleIconWrapper onClick={handleToggleVisibility}>
          <EyeIcon isVisible={showPassword} />
        </VisibleIconWrapper>
      ) : null}
      {isError && type === 'email' ? (
        <ErrorText>Please, enter correct email address.</ErrorText>
      ) : null}
      {isError && placeholder === 'Confirm password' ? (
        <ErrorText>Password should match</ErrorText>
      ) : null}
    </InputWrapper>
  );
};

export default CustomInput;
