import types from 'redux/types';

export const getAllTeamsAction = (leagueId) => ({
  type: types.GET_TEAMS_START,
  payload: leagueId,
});

export const allTeamsResetAction = () => ({
  type: types.GET_TEAMS_RESET,
  payload: null,
});

export const setSelectedTeamAction = (team) => ({
  type: types.SET_SELECTED_TEAM,
  payload: { teamName: team.label, teamId: team.value },
});
