import styled from 'styled-components';

import { device } from 'theme';

export const ErrorMessageWrapper = styled.div`
  width: 280px;
  margin: 50px auto;

  @media ${device.tablet} {
    width: 600px;
    margin-top: 100px auto;
  }

  @media ${device.laptop} {
    width: 800px;
    margin: 150px auto;
  }
`;
