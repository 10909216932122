import types from '../types';

const initialState = [];

const todaysGames = (
  state = { loading: false, data: initialState, error: null },
  action
) => {
  switch (action.type) {
    case types.GET_TODAYS_GAMES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

export default todaysGames;
