import moment from 'moment-timezone';
import { useGetUserTimeZone } from 'hooks';

const getNowDate = (timeZone) =>
  moment.utc().tz(timeZone || 'America/New_York');

export const isGameCurrent = ({ games, isChampionships = false }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { timeZone: userTimeZone } = useGetUserTimeZone();

  if (isChampionships) {
    const currentGameIndex = games.findIndex(
      (i) =>
        moment
          .utc(i.event_start, 'YYYY-MM-DD HH:mm:ss')
          .tz(userTimeZone || i.stadium_time_zone)
          .valueOf() > getNowDate(userTimeZone || i.stadium_time_zone)
    );

    const gameIndex =
      currentGameIndex === -1 ? games.length - 1 : currentGameIndex;
    return gameIndex;
  } else {
    const currentGameIndex = games.findIndex(
      (i) =>
        (moment.utc(i.event_start, 'YYYY-MM-DD HH:mm:ss').valueOf() >
          getNowDate(userTimeZone || i.stadium_time_zone) ||
          getNowDate(userTimeZone || i.stadium_time_zone) <
            moment
              .utc(i.event_start, 'YYYY-MM-DD HH:mm:ss')
              .add(4, 'hours')
              .valueOf()) &&
        getNowDate(userTimeZone || i.stadium_time_zone) <
          moment
            .utc(i.event_start, 'YYYY-MM-DD HH:mm:ss')
            .tz(userTimeZone || i.stadium_time_zone)
            .add(4, 'hours')
            .valueOf()
    );
    return currentGameIndex;
  }
};
