import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { truncateText, getLatestNews } from 'helpers';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const NewsSlide = ({ news }) => {
  const initialNewsIndex = getLatestNews(news);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    initialSlide: initialNewsIndex,
    pauseOnFocus: true,
    pauseOnHover: true,
    speed: 700,
    className: 'newsSlide',
  };

  return (
    <Slider {...settings}>
      {news.map((newsItem) => (
        <Link key={newsItem.news_id} target="_blank" to={newsItem.news_url}>
          {truncateText(newsItem.news_title, 150)}
        </Link>
      ))}
    </Slider>
  );
};
