import { color, device, fontSize } from 'theme';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  height: 60px;
`;
export const Input = styled.input`
  position: relative;
  width: 300px;
  padding: 20px 10px 10px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: ${fontSize.small};
  letter-spacing: 0.05em;
  z-index: 10;

  &:valid ~ span,
  &:focus ~ span {
    color: ${color.red};
    transform: translateX(-10px) translateY(-34px);
    font-size: 0.85em;
    letter-spacing: 0;
  }

  &:valid ~ i,
  &:focus ~ i {
    height: 54px;
  }

  & ~ i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${(props) => (props.$isError ? '54px' : '2px')};
    background: ${color.red};
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 9;
  }

  @media ${device.tablet} {
    font-size: ${fontSize.large};
  }

  @media ${device.laptop} {
    font-size: ${fontSize.mLarge};
  }
`;
export const InputLabel = styled.span`
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: ${fontSize.small};
  color: ${color.greyText};
  letter-spacing: 0.05em;
  pointer-events: none;
  transition: 0.5s;

  @media ${device.tablet} {
    font-size: ${fontSize.medium};
  }

  @media ${device.laptop} {
    font-size: ${fontSize.large};
  }
`;

export const ErrorText = styled.div`
  color: red;
  padding-top: 10px;
`;

export const VisibleIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 40%;
  z-index: 999;
  overflow: hidden;
  cursor: pointer;
`;
