import moment from 'moment';

export const removePastGames = (games) => {
  if (!games) {
    return [];
  }

  const futureGames = games.filter((game) =>
    moment(game.event_end).isAfter(moment())
  );

  return futureGames;
};
