import React from 'react';

export const TemperatureIcon = () => (
  <svg
    className="weather-icon"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="42.thermometer" id="_42.thermometer">
      <path d="M36.11,39.44V10.74a6.71,6.71,0,0,0-13.41,0v28.7C13.88,45.73,18.56,60.06,29.41,60,40.25,60.06,44.94,45.73,36.11,39.44ZM29.41,58c-9.18.07-12.86-12.21-5.15-17.19a1.15,1.15,0,0,0,.44-.84V10.74a4.71,4.71,0,0,1,9.41,0V39.93a1.22,1.22,0,0,0,.45.84C42.26,45.74,38.58,58,29.41,58Z" />
      <path d="M45,34.63h-5.7a1,1,0,0,0,0,2H45A1,1,0,0,0,45,34.63Z" />
      <path d="M45,30.48h-5.7a1,1,0,0,0,0,2H45A1,1,0,0,0,45,30.48Z" />
      <path d="M45,26.33h-5.7a1,1,0,0,0,0,2H45A1,1,0,0,0,45,26.33Z" />
      <path d="M45,22.19h-5.7a1,1,0,0,0,0,2H45A1,1,0,0,0,45,22.19Z" />
      <path d="M45,18h-5.7a1,1,0,0,0,0,2H45A1,1,0,0,0,45,18Z" />
      <path d="M39.26,15.89H45a1,1,0,0,0,0-2h-5.7A1,1,0,0,0,39.26,15.89Z" />
      <path d="M30.41,41.45V12.3a1,1,0,0,0-2,0V41.45c-8.78,1.42-7.95,14.14,1,14.36C38.36,55.59,39.19,42.87,30.41,41.45Zm-1,12.36c-6.9-.22-6.9-10.22,0-10.44C36.31,43.59,36.31,53.6,29.41,53.81Z" />
    </g>
  </svg>
);
