import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { CloseRoundButton } from 'components/Button/style.js';
import {
  ModalWrapper,
  ModalContainer,
  ButtonWrapper,
  InnerContainer,
} from './style.js';

const Modal = ({ show, onClose, children, showCloseBtn = true }) => {
  const ref = useRef();

  const closeOnEscapeKeyDown = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (e) => {
    if (show && ref.current && !ref.current?.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return ReactDOM.createPortal(
    <ModalWrapper $show={show}>
      <ModalContainer $show={show} ref={ref}>
        <InnerContainer>
          {showCloseBtn ? (
            <ButtonWrapper>
              <CloseRoundButton onClick={onClose} />
            </ButtonWrapper>
          ) : null}
          {children}
        </InnerContainer>
      </ModalContainer>
    </ModalWrapper>,
    document.getElementById('root')
  );
};

export default Modal;
