import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';

import {
  ErrorMessage,
  GameInfoButton,
  Teams,
  TvChannel,
  GameResult,
} from 'components';
import GameDate from 'components/GameDate';
import { CurrentGameButton } from './components/CurrentGameButton';

import 'swiper/swiper-bundle.css';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

export const DashboardChampionship = ({ games, currentGame }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams({
    game: currentGame,
  });

  //** FIND event_id FUNCTION */
  const slideChanged = useCallback(
    (index) => {
      if (!games.length) return;
      const oneGame = games[index]?.event_id;
      if (oneGame) {
        setSearchParams(
          (prevValue) => {
            prevValue.set('game', oneGame);
            return prevValue;
          },
          { replace: true }
        );
      }
    },
    [games, setSearchParams]
  );

  if (!games.length) {
    return (
      <StyledSection>
        <ErrorMessage title="Unable to display events. Please make another selection or try again later." />
      </StyledSection>
    );
  }

  return (
    <>
      <LeftSideBar>
        <div className="swiper-button-prev-unique" />
      </LeftSideBar>
      <StyledSection>
        <Swiper
          grabCursor={true}
          id="main-slider"
          initialSlide={currentGame}
          modules={[Navigation, Mousewheel]}
          mousewheel={true}
          navigation={{
            prevEl: '.swiper-button-prev-unique',
            nextEl: '.swiper-button-next-unique',
          }}
          onSlideChange={(swiper) => {
            slideChanged(swiper.activeIndex);
          }}
          slidesPerView={1}
          tag="section"
          wrapperTag="ul"
        >
          {games?.map((game) => (
            <SwiperSlide key={game.event_id} tag="li">
              <SlideContent>
                <Teams team={game.event_text} />
                <GameInfoButton
                  city={game.location_city}
                  date={game.event_start}
                  opponent={game.event_text}
                  region={game.stadium_country}
                  stadium={game.stadium_name}
                  stadiumAddress={game.stadium_address}
                  stadiumLocation={{
                    lat: game?.stadium_lat,
                    lng: game?.stadium_lng,
                  }}
                  team=""
                  timeZone={game.stadium_time_zone}
                  zipCode={game.stadium_zip_code}
                />
                <GameDate
                  endDate={game.event_end}
                  eventState={game.event_state}
                  eventTime={game.event_start}
                  isChampionship
                />
                <TvChannel channel={game.event_tv_channel} />
                <GameResult
                  date={game.event_start}
                  endDate={game.event_end}
                  result={game.event_outcome}
                />
                <CurrentGameButton isChampionship />
              </SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSection>
      <RightSideBar>
        <div className="swiper-button-next-unique" />
      </RightSideBar>{' '}
    </>
  );
};
