import moment from 'moment';

const now = moment.utc(new Date().getTime()).valueOf();

export const useGetCurrentEventsList = (events) => {
  let currentEventsList = [];
  let currentEventIndex = 0;

  if (!Array.isArray(events) || events.length === 0) {
    return {
      currentEventIndex,
      currentEventsList,
    };
  } else {
    const sortedEventsByDate = events.sort(
      (a, b) => new Date(a.event_start) - new Date(b.event_start)
    );
    currentEventIndex = sortedEventsByDate.findIndex(
      (event) =>
        moment.utc(event.event_start, 'YYYY-MM-DD HH:mm:ss').valueOf() >= now
    );

    currentEventsList = sortedEventsByDate.slice(
      currentEventIndex,
      events.length
    );
  }

  return {
    currentEventIndex,
    currentEventsList,
  };
};
