import { ROUTES } from 'constants/routes.js';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { api } from 'api/api.js';
import Select from 'react-select';
import useLocalStorage from 'hooks';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  StyledSelect,
  SelectContainer,
  StyledLabel,
  // SelectTitles,
  BackgroundImg,
  selectorColors,
} from './style';

export const CustomEventsHeader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeId = searchParams.get('typeId');
  const type = searchParams.get('type');

  // Log google analytics events
  const analytics = getAnalytics();

  const {
    status: eventStatus,
    data: eventList,
    isLoading: isEventLoading,
  } = useQuery('getEvents', () => api.customEvents.getAllEvents(), {
    onError: () => {
      toast.error(
        'An error occurred while loading events list. Please try again later.'
      );

      logEvent(analytics, 'select_content', {
        content_type: 'error',
        content_id: `Loading custom events failed for ${window.location.href}`,
      });
    },
  });

  const getEventFormUrl = (type, typeId) => {
    if (!type && !typeId) return '';
    const event = { label: type, value: typeId };
    return event;
  };

  const [savedEvent, setSavedEvent] = useLocalStorage(
    'chosen-event-type',
    getEventFormUrl(type, typeId)
  );

  const handleEventChange = useCallback(
    (selectedEventType) => {
      setSavedEvent(selectedEventType);

      logEvent(analytics, 'select_content', {
        content_type: 'Changed Custom event',
        content_id: selectedEventType.label,
      });
    },
    [setSavedEvent, analytics]
  );

  useEffect(() => {
    navigate(
      `/${ROUTES.CUSTOM_EVENTS}?type=${savedEvent.label}&typeId=${savedEvent.value}`
    );
  }, [savedEvent.label, savedEvent.value, navigate]);

  return (
    <>
      <BackgroundImg $imgUrl="" />
      <SelectContainer isCentered={true}>
        <StyledLabel>
          {/* <SelectTitles>Event type</SelectTitles> */}

          {eventStatus === 'success' ? (
            <Select
              components={{ IndicatorSeparator: () => null }}
              defaultValue={savedEvent}
              isDisabled={isEventLoading || !eventList}
              isSearchable={true}
              onChange={handleEventChange}
              options={eventList?.data.map((event) => ({
                label: event.type_name,
                value: event.type_id,
              }))}
              selected={savedEvent}
              styles={StyledSelect}
              theme={{
                colors: selectorColors,
              }}
            />
          ) : null}
        </StyledLabel>
      </SelectContainer>
    </>
  );
};
