export const leagueNames = {
  NBA: 'NBA',
  WNBA: 'WNBA',
  NCAAM: 'NCAAM',
  NCAAW: 'NCAAW',
  MLB: 'MLB',
  MLS: 'MLS',
  EPL: 'EPL',
  NFL: 'NFL',
  NCAAF: 'NCAAF',
  NHL: 'NHL',
  ATP: 'ATP',
  WTA: 'WTA',
  PGA: 'PGA',
  LALIGA: 'LALIGA',
  FIFA_WORLD_CUP: 'FIFA WORLD CUP',
  HORSE_RACING: 'HORSE RACING',
  EURO_CHAMPIONSHIP: 'EURO CHAMPIONSHIP',
  CHAMPIONS_LEAGUE: 'CHAMPIONS LEAGUE',
  NCAA_BASEBALL: 'NCAA BASEBALL',
  WOMENS_WORLD_CUP: "WOMEN'S WORLD CUP",
};

export const backgroundNames = {
  basketball: 'basketball-background',
  baseball: 'baseball-background',
  soccer: 'soccer-background',
  football: 'football-background',
  hockey: 'hockey-background',
  default: 'basic-background',
  tennis: 'tennis-background',
  golf: 'golf-background',
  horseRacing: 'horseracing-background',
};
