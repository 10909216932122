import React, { memo } from 'react';

import { CountdowItem, CountdowItemNumber, CountdowItemDesc } from '../style';

const Minutes = ({ minutes }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {minutes >= 0 ? (
      <CountdowItem>
        <CountdowItemNumber>
          {minutes < 10 ? '0' + minutes : minutes}
        </CountdowItemNumber>
        <CountdowItemDesc>min</CountdowItemDesc>
      </CountdowItem>
    ) : null}
  </>
);

function arePropsEqual(prevProps, nextProps) {
  return prevProps.minutes === nextProps.minutes;
}

export default memo(Minutes, arePropsEqual);
