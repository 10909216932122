import { combineReducers } from 'redux';
import configuration from './config/reducer';
import favoriteTeams from './favoriteTeams/reducer';
import games from './games/reducer';
import leagues from './leagues/reducer';
import selectedLeague from './oneLeague/reducer';
import selectedTeam from './oneTeam/reducer';
import teams from './teams/reducer';
import user from './user/reducer';
import todaysGames from './todaysGames/reducer';

export default combineReducers({
  configuration,
  favoriteTeams,
  games,
  todaysGames,
  leagues,
  selectedLeague,
  selectedTeam,
  teams,
  user,
});
