import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import { isGameCurrent } from 'helpers';
import styled from 'styled-components';

import { ForwardIcon, BackwardIcon } from 'assets/icons';
import Button from 'components/Button';

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  animation: appearance 1s ease-in;

  & > button {
    box-shadow: 0px 0px 0px 1px transparent;
  }

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const EmptyPlaceholder = styled.div`
  width: 40px;
  height: 40px;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
`;

export const CurrentGameButton = ({ isChampionship = false }) => {
  const games = useSelector((state) => state.games.data);
  const swiper = useSwiper();

  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('game');

  if (!games) {
    return null;
  }

  const todayGameIndex = isGameCurrent({
    games: games,
    isChampionships: isChampionship,
  });
  const currentGame = games[todayGameIndex];

  if (!currentGame || currentGame?.event_id === Number(gameId)) {
    return <EmptyPlaceholder />;
  }

  const displayedGame = games.find((game) => game.event_id === Number(gameId));
  const isSlideForward =
    new Date(displayedGame?.event_start) > new Date(currentGame?.event_start);

  return (
    <BtnWrapper>
      <Button
        before={isSlideForward}
        icon={
          <IconWrapper>
            {isSlideForward ? <BackwardIcon /> : <ForwardIcon />}
          </IconWrapper>
        }
        onClick={() => {
          swiper.slideTo(todayGameIndex);
        }}
        withIcon
      />
    </BtnWrapper>
  );
};
