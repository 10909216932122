import types from 'redux/types';

export const getAllGamesAction = (teamId) => ({
  type: types.GET_GAMES_START,
  payload: teamId,
});

export const allGamesResetAction = () => ({
  type: types.GET_GAMES_RESET,
  payload: [],
});

export const setAllGamesInfo = () => ({
  type: types.GET_GAMES_SUCCESS,
});
