import styled from 'styled-components';

import { device, color } from 'theme';

export const AuthWrapper = styled.div`
  position: absolute;
  right: 5%;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    top: 10%;
  }

  @media ${device.laptop} {
    top: 25%;
  }

  color: white;
`;

export const FormTitle = styled.h3`
  color: ${color.red};
  text-align: center;
  font-weight: normal;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-around;

  > * {
    &:first-child {
      margin-bottom: 15px;
    }
  }
`;

export const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;

  @media ${device.tablet} {
    padding: 15px 10px;
  }

  @media ${device.laptop} {
    padding: 15px 30px;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  width: 25px;
  margin-right: 15px;
`;

export const UserNameWrapper = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;

  @media ${device.tablet} {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  & > svg {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
  }

  & > svg:hover {
    transform: scale(1.1);
  }
`;

export const LoginBtnContainer = styled.div`
  display: flex;
  gap: 60px;
`;
