import React, { useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { color } from 'theme';

const TooltipWrapper = styled.div`
  position: relative;
`;

const TooltipText = styled.p`
  position: absolute;
  top: -55px;
  left: 0;
  width: 200px;
  z-index: 999;
  padding: 5px;
  border: 1px solid ${color.red};
  border-radius: 5px;
  background: ${color.backgroundDark};
  color: #fff;
  text-align: center;
  transform-origin: left;
  transform: ${(props) => (props.$isOpen ? 'scaleX(1)' : 'scaleX(0)')};
  transition: all 0.2s linear;
`;

export const Tooltip = ({ text, children, isActive = true }) => {
  const tooltipRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const mouseHoverHandler = useCallback(() => {
    if (isActive) {
      setShowTooltip((prevState) => !prevState);
    }
  }, [isActive]);

  return (
    <>
      <TooltipWrapper
        onMouseEnter={mouseHoverHandler}
        onMouseLeave={mouseHoverHandler}
        ref={tooltipRef}
      >
        {children}
      </TooltipWrapper>
      {tooltipRef.current
        ? ReactDOM.createPortal(
            <TooltipText $isOpen={showTooltip}>{text}</TooltipText>,
            tooltipRef.current
          )
        : null}
    </>
  );
};
