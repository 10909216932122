export const createSelectedValue = ({ value, label }) => {
  if (!value && !label) {
    return null;
  }

  return {
    value: value,
    label: label,
  };
};
