import React from 'react';
import backgroundImg from 'assets/img/basic-background.jpg';
import { AuthWrapper } from 'components/AuthContainer/AuthWrapper';
import Modal from 'components/Modal';
import SetNewPasswordForm from 'components/AuthContainer/SetNewPasswordForm';

import { BodyContainer } from 'components/App/style.js';
import { BackgroundImg } from 'components/Header/style.js';

const ChangePassword = () => (
  <BodyContainer>
    <BackgroundImg $imgUrl={`${backgroundImg}`} />
    <Modal show={true} showCloseBtn={false}>
      <AuthWrapper title="Enter Your registration email and new password">
        <SetNewPasswordForm />
      </AuthWrapper>
    </Modal>
  </BodyContainer>
);

export default ChangePassword;
