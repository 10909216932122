import { leagueNames, backgroundNames } from 'constants/backgroundNames';

export const getBackgroundName = (leagueName) => {
  switch (leagueName) {
    case leagueNames.NBA:
    case leagueNames.WNBA:
    case leagueNames.NCAAM:
    case leagueNames.NCAAW:
      return backgroundNames.basketball;

    case leagueNames.MLB:
    case leagueNames.NCAA_BASEBALL:
      return backgroundNames.baseball;

    case leagueNames.MLS:
    case leagueNames.EPL:
    case leagueNames.LALIGA:
    case leagueNames.FIFA_WORLD_CUP:
    case leagueNames.EURO_CHAMPIONSHIP:
    case leagueNames.CHAMPIONS_LEAGUE:
    case leagueNames.WOMENS_WORLD_CUP:
      return backgroundNames.soccer;

    case leagueNames.NFL:
    case leagueNames.NCAAF:
      return backgroundNames.football;

    case leagueNames.NHL:
      return backgroundNames.hockey;

    case leagueNames.ATP:
    case leagueNames.WTA:
      return backgroundNames.tennis;

    case leagueNames.PGA:
      return backgroundNames.golf;

    case leagueNames.HORSE_RACING:
      return backgroundNames.horseRacing;

    default:
      return backgroundNames.default;
  }
};
