import React, { memo } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { useGetUserTimeZone } from 'hooks';

import { DateContainer } from './style';

const GameDate = ({
  eventTime,
  eventState = '3',
  timeZone,
  isChampionship = false,
  endDate,
}) => {
  const { timeZone: userTimeZone } = useGetUserTimeZone();

  if (eventState === '1') {
    return null;
  }

  return (
    <DateContainer>
      {eventState === '2' ? (
        <Moment element="span" format="dddd, MMMM D,  YYYY">
          {moment
            .utc(eventTime)
            .tz(userTimeZone || timeZone || 'America/New_York')}
        </Moment>
      ) : null}

      {eventState === '3' ? (
        <>
          <Moment element="span" format="ddd, MMM D,  YYYY">
            {moment
              .utc(eventTime)
              .tz(userTimeZone || timeZone || 'America/New_York')}
          </Moment>{' '}
          {!isChampionship ? (
            <>
              <span>at</span>{' '}
              <Moment element="span" format="h:mm A z">
                {moment
                  .utc(eventTime)
                  .tz(userTimeZone || timeZone || 'America/New_York')}
              </Moment>
            </>
          ) : null}
          {isChampionship && endDate ? (
            <>
              <span> - </span>
              <Moment element="span" format="ddd, MMM D,  YYYY">
                {moment
                  .utc(endDate)
                  .tz(userTimeZone || timeZone || 'America/New_York')}
              </Moment>{' '}
            </>
          ) : null}
        </>
      ) : null}
    </DateContainer>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.eventTime === nextProps.eventTime;
}

export default memo(GameDate, arePropsEqual);
