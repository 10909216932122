import styled from 'styled-components';
import { color, device, fontSize } from 'theme';
import { BodyContainer } from 'components/App/style.js';

export const StyledBodyContainer = styled(BodyContainer)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  margin: 10px 10px 5px 10px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 5px 10px 5px;

  h1 {
    text-align: center;
    color: ${color.red};
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  h2,
  h3 {
    text-align: center;
    margin-bottom: 12px;
  }

  p,
  h2,
  h3,
  li {
    color: #ececec;
  }

  p {
    margin-bottom: 8px;
    font-size: ${fontSize.regular};
  }

  h4,
  strong {
    color: #fff;
    font-size: ${fontSize.medium};
  }

  ul {
    padding-left: 15px;
    margin-bottom: 8px;

    & > li {
      padding-left: 10px;
    }
  }

  a {
    color: ${color.red};
  }

  @media ${device.tablet} {
    margin: 45px 30px 5px 30px;
  }

  @media ${device.laptop} {
    margin: 50px 200px 10px 200px;
  }
`;

export const HighlightedText = styled.span`
  color: ${color.red};
`;
