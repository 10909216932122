import { LOCAL_STORAGE_KEY } from 'constants';
import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Button from 'components/Button';

import { BackgroundImg } from 'components/Header/style.js';
import imagePath from 'assets/img/basic-background.jpg';
import { StyledTitle } from 'theme/titles.js';
import { ErrorBoundaryWrapper } from './style.js';

const analytics = getAnalytics();

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unexpected error: ${errorInfo}. Site url: ${window.location.href}`,
    });
  }

  handleBack() {
    localStorage.removeItem(`${LOCAL_STORAGE_KEY}chosen-team`);
    localStorage.removeItem(`${LOCAL_STORAGE_KEY}chosen-league`);

    setTimeout(() => {
      window.location.assign('/');
    }, 500);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <BackgroundImg $imgUrl={imagePath} />
          <ErrorBoundaryWrapper>
            <StyledTitle>
              Oops, there seems to have been an unexpected error...
            </StyledTitle>

            <Button onClick={this.handleBack} title="Back to main page" />
          </ErrorBoundaryWrapper>
        </>
      );
    }

    return children;
  }
}
