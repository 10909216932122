import { LOCAL_STORAGE_KEY } from 'constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'api/api';
import types from '../types';

function* userLogin({ payload }) {
  try {
    const {
      data: { data },
      status,
    } = yield call(api.user.loginUser, payload);
    if (status === 200) {
      yield put({ type: types.USER_LOGIN_SUCCESS, payload: data });
      yield localStorage.setItem(
        `${LOCAL_STORAGE_KEY}access-token`,
        JSON.stringify(data)
      );
    } else {
      yield put({
        type: types.USER_LOGIN_ERROR,
        payload:
          "It's seems there is an error ocurred while login. Please try again later.",
      });
    }
  } catch (error) {
    const { status } = error.response;

    if (status === 401 || status === 422) {
      yield put({
        type: types.USER_LOGIN_ERROR,
        payload:
          'There is no user with such credentials. Make sure Your login and password are correct.',
      });
    } else {
      yield put({
        type: types.USER_LOGIN_ERROR,
        payload: 'Something went wrong. Unable to sign in.',
      });
    }
  }
}

export function* userLoginWatcher() {
  yield takeLatest(types.USER_LOGIN_START, userLogin);
}
