import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetChampionshipGames } from 'hooks';
import { hexToRgba } from 'helpers';
import { setAllGamesInfo } from 'redux/games/actionCreators';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  ChampionshipHeader,
  ErrorMessage,
  Loading,
  // TEMP - Don't show login until later...
  // AuthContainer,
} from 'components';
import { DashboardChampionship } from 'components/Dashboard/DashboardChampionship';

import {
  BodyContainer,
  MainContainer,
  StyledHeader,
  StyledFooter,
} from 'components/App/style';

const ChampionshipPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  // ** Log Google Analytics ** //
  const analytics = getAnalytics();

  const {
    loading: isConfigLoading,
    error: isConfigError,
    data: configData,
  } = useSelector((state) => state.configuration);

  const {
    data: leaguesData,
    loading: leaguesLoading,
    error: leagueError,
  } = useSelector((state) => state.leagues);

  const {
    data: teamsData,
    status: teamsStatus,
    error: teamsError,
  } = useGetChampionshipGames({
    leagueId: params?.leagueId,
    league: params?.league,
  });

  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    if (configData?.style_main_color) {
      setBackgroundColor(configData?.style_main_color);
    }
  }, [configData?.style_main_color]);

  useEffect(() => {
    if (teamsData) {
      dispatch(setAllGamesInfo(teamsData.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsData]);

  if (isConfigLoading || leaguesLoading || teamsStatus === 'loading') {
    return <Loading />;
  }

  if (isConfigError) {
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unable to load site configuration for ${window.location.href}`,
    });

    return (
      <ErrorMessage
        title=" Oops... Service currently unavailable. We'are working on it. Please,
      try again later."
      />
    );
  }

  if (leagueError || teamsError === 'error') {
    const message = leagueError || teamsError;
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unable to get schedule data: ${message}. Site: ${window.location.href}`,
    });

    return <ErrorMessage />;
  }

  return (
    <BodyContainer
      $backgroundColor={backgroundColor ? hexToRgba(backgroundColor) : '000000'}
    >
      <StyledHeader>
        {/* <AuthContainer /> */}
        <ChampionshipHeader
          configuration={configData || null}
          leagues={leaguesData}
        />
      </StyledHeader>

      <MainContainer>
        <DashboardChampionship
          currentGame={teamsData.currentGame}
          games={teamsData.data}
        />
      </MainContainer>
      <StyledFooter />
    </BodyContainer>
  );
};

export default ChampionshipPage;
