import types from '../types';

const favoriteTeams = (
  state = { loading: true, data: null, error: null },
  action
) => {
  switch (action.type) {
    case types.GET_FAVORITE_TEAMS_SUCCESS:
      return { loading: false, error: null, data: action.payload };
    case types.GET_FAVORITE_TEAMS_ERROR:
      return { loading: false, data: null, error: action.payload };

    default:
      return state;
  }
};

export default favoriteTeams;
