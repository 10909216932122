import styled from 'styled-components';

import { device } from 'theme';

export const StyledSection = styled.section`
  width: 70%;
  flex: 1 1 auto;

  @media ${device.tablet} {
    width: 50%;
  }
`;

export const LeftSideBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    flex: 0 0 130px;
  }

  @media ${device.laptop} {
    flex: 0 0 260px;
  }
`;

export const RightSideBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    flex: 0 0 130px;
  }

  @media ${device.laptop} {
    flex: 0 0 260px;
  }
`;

export const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    height: calc(100vh - 240px);
  }
`;
