import { CHOSEN_LEAGUE, CHOSEN_TEAM } from 'constants/localStorageKey';
import { championshipLeagues, ROUTES, UPCOMING_GAMES_LABEL } from 'constants';
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { api } from 'api/api';
import {
  favoriteTeamsError,
  favoriteTeamsSuccess,
} from 'redux/favoriteTeams/actionCreators.js';
import useLocalStorage from 'hooks';
import types from 'redux/types';

export const Wrapper = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memoizedUserState = createSelector(
    [(state) => state?.user],
    (user) => user.data
  );

  const memoizedConfigState = createSelector(
    [(state) => state.configuration],
    (configuration) => configuration.data
  );

  const { user_id: userId } = useSelector(memoizedUserState);
  const configuration = useSelector(memoizedConfigState);
  const [searchParams, setSearchParams] = useSearchParams();

  const [savedLeague, setSavedLeague] = useLocalStorage(CHOSEN_LEAGUE, '');
  const [savedTeam] = useLocalStorage(CHOSEN_TEAM, '');

  const params = Object.fromEntries([...searchParams]);
  const url = window.location.href;

  const isUpcomingGames = new RegExp(ROUTES.UPCOMING_GAMES).test(url);

  //** GET ALL FAVORITE TEAMS FOR LOGIN USER **//
  const getAllFavoriteTeams = useMutation(
    (id) => api.favorites.getFavoritesTeams(id),
    {
      onError: () => {
        dispatch(favoriteTeamsError());
      },
      onSuccess: (data) => {
        dispatch(favoriteTeamsSuccess(data.data));
      },
    }
  );

  // ** GET LEAGUES LIST ** //
  useEffect(() => {
    if (configuration && configuration.display_leagues_select === '1') {
      dispatch({
        type: types.GET_LEAGUES_START,
      });
    }
  }, [configuration, dispatch]);

  //** GETTING ALL FAVORITE TEAMS IF USER ID IS AVAILABLE **//
  useEffect(() => {
    if (userId && configuration?.display_leagues_select === '1') {
      getAllFavoriteTeams.mutate(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, configuration]);

  //** SET SELECTED LEAGUE AND TEAM TO LOCAL STORAGE **//
  useEffect(() => {
    if (params?.league && params?.leagueId) {
      setSavedLeague({ league: params?.league, leagueId: params?.leagueId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.league, params?.leagueId, params?.team, params?.teamId]);

  //** SET SELECTED LEAGUE TO URL SEARCH QUERY IF SAVED PREVIOUSLY TO LOCAL STORAGE **//
  useEffect(() => {
    if (
      Boolean(savedLeague) &&
      configuration?.display_leagues_select === '1' &&
      championshipLeagues.includes(savedLeague.league) &&
      !params?.league &&
      !params?.leagueId &&
      !isUpcomingGames
    ) {
      const { league, leagueId } = savedLeague;
      navigate(
        `/${ROUTES.CHAMPIONSHIPS}?league=${league}&leagueId=${leagueId}`
      );
    } else if (
      Boolean(savedLeague) &&
      configuration?.display_leagues_select === '1' &&
      !championshipLeagues.includes(savedLeague.league) &&
      !params?.league &&
      !params?.leagueId
    ) {
      const { league, leagueId } = savedLeague;
      setSearchParams(
        (prevValue) => {
          prevValue.set('league', league);
          prevValue.set('leagueId', leagueId);
          return prevValue;
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //** SET SELECTED TEAM TO URL SEARCH QUERY IF SAVED PREVIOUSLY TO LOCAL STORAGE **//
  useEffect(
    () => {
      if (
        Boolean(savedTeam) &&
        (configuration?.display_leagues_select === '1' ||
          configuration?.display_teams_select === '1') &&
        !championshipLeagues.includes(savedLeague.league)
      ) {
        const { team, teamId } = savedTeam;
        setSearchParams(
          (prevValue) => {
            prevValue.set('team', team);
            prevValue.set('teamId', teamId);
            return prevValue;
          },
          { replace: true }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (configuration?.display_teams_select === '1' && !params?.team) {
      setSearchParams(
        (prevValue) => {
          prevValue.set('league', configuration?.one_league_name);
          prevValue.set('leagueId', configuration?.one_league_id);
          prevValue.set('team', UPCOMING_GAMES_LABEL);
          prevValue.set('teamId', configuration.one_league_id);
          return prevValue;
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration?.display_teams_select]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
