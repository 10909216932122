import React from 'react';
import styled from 'styled-components';

import { color, device } from 'theme';
import { Text } from 'theme/titles';

const TvChannelText = styled(Text)`
  font-size: 0.8rem;
  color: ${color.lightGrey};
  letter-spacing: 0.1rem;
  padding: 2px;

  @media ${device.tablet} {
    font-size: 1.3rem;
  }

  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`;

export const TvChannel = ({ channel }) => (
  <TvChannelText>
    {channel !== '' && channel !== null ? <span>on {channel}</span> : null}
  </TvChannelText>
);
